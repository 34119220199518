/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientMasterKeterangan } from '../models/client-master-keterangan';

@Injectable({
  providedIn: 'root',
})
export class ClientMasterKeteranganService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientgetmasterKeterangansList
   */
  static readonly ClientgetmasterKeterangansListPath = '/client/masterKeterangans';

  /**
   * Get list of masterKeterangans.
   *
   * Returns list of masterKeterangans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientgetmasterKeterangansList()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetmasterKeterangansList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterKeterangan>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterKeteranganService.ClientgetmasterKeterangansListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientMasterKeterangan>;
        }>;
      })
    );
  }

  /**
   * Get list of masterKeterangans.
   *
   * Returns list of masterKeterangans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientgetmasterKeterangansList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetmasterKeterangansList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterKeterangan>;
}> {

    return this.clientgetmasterKeterangansList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterKeterangan>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterKeterangan>;
})
    );
  }

  /**
   * Path part for operation clientgetMasterKeteranganId
   */
  static readonly ClientgetMasterKeteranganIdPath = '/client/masterKeterangans/{id}';

  /**
   * Get  Master_keterangan by id.
   *
   * Returns Master_keterangan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientgetMasterKeteranganId()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetMasterKeteranganId$Response(params: {

    /**
     * id of Master_keterangan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterKeterangan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterKeteranganService.ClientgetMasterKeteranganIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientMasterKeterangan;
        }>;
      })
    );
  }

  /**
   * Get  Master_keterangan by id.
   *
   * Returns Master_keterangan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientgetMasterKeteranganId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetMasterKeteranganId(params: {

    /**
     * id of Master_keterangan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterKeterangan;
}> {

    return this.clientgetMasterKeteranganId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterKeterangan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterKeterangan;
})
    );
  }

}
