<!-- Title -->
<div class="breadcrumb-title">
    <h1 class="mb-0 fw-semibold">
        Post A New Job!
    </h1>
</div>

<!-- Post A New Job -->
<div class="jove-card">
    <form>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label>
                        Job title
                    </label>
                    <input type="text" class="form-control shadow-none" placeholder="Title">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label>
                        Job description
                    </label>
                    <textarea class="form-control shadow-none" cols="30" rows="8" placeholder="Welcome to a world of endless possibilities at Jove! It's not just a job search; it's a career evolution. Uncover your potential, chase your ambitions, and step into a realm where your dream job is not just a fantasy – it's waiting for you."></textarea>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Email address
                    </label>
                    <input type="email" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Username
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Specialisms
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>Category</option>
                        <option value="1">Engineering</option>
                        <option value="2">Banking</option>
                        <option value="3">Beauty Care</option>
                        <option value="4">Cleaning</option>
                        <option value="5">Construction</option>
                        <option value="6">Finance</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Job type
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>Select</option>
                        <option value="1">Engineering</option>
                        <option value="2">Banking</option>
                        <option value="3">Beauty Care</option>
                        <option value="4">Cleaning</option>
                        <option value="5">Construction</option>
                        <option value="6">Finance</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Offered salary (monthly)
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>Select</option>
                        <option value="1">$1000</option>
                        <option value="2">$2000</option>
                        <option value="3">$3000</option>
                        <option value="4">$4000</option>
                        <option value="5">$5000</option>
                        <option value="6">$6000</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Career level
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>Select</option>
                        <option value="1">Manager</option>
                        <option value="2">Officer</option>
                        <option value="3">Student</option>
                        <option value="4">Executive</option>
                        <option value="5">Others</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Experience
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>Select</option>
                        <option value="1">Fresher</option>
                        <option value="2">1 year</option>
                        <option value="3">2 years</option>
                        <option value="4">3 years</option>
                        <option value="5">4 years</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Gender
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>Select</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        <option value="3">Custom</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Industry
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>Select</option>
                        <option value="1">Engineering</option>
                        <option value="2">Banking</option>
                        <option value="3">Beauty Care</option>
                        <option value="4">Cleaning</option>
                        <option value="5">Construction</option>
                        <option value="6">Finance</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Qualification
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>Select</option>
                        <option value="1">Certificate</option>
                        <option value="2">Diploma</option>
                        <option value="3">Bachelor Degree</option>
                        <option value="4">Master’s Degree</option>
                        <option value="5">Associate</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label>
                        Application deadline date
                    </label>
                    <input type="text" class="form-control shadow-none" placeholder="10 Nov 2023">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Country
                    </label>
                    <select class="form-select shadow-none">
                        <option value="1" selected>Japan</option>
                        <option value="2">USA</option>
                        <option value="3">England</option>
                        <option value="4">China</option>
                        <option value="5">Canada</option>
                        <option value="6">Brazil</option>
                        <option value="7">South Korea</option>
                        <option value="8">Spain</option>
                        <option value="9">Turkey</option>
                        <option value="10">Japan</option>
                        <option value="11">Denmark</option>
                        <option value="12">Thailand</option>
                        <option value="13">UAE</option>
                        <option value="14">Australia</option>
                        <option value="15">Netherlands</option>
                        <option value="16">France</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        City
                    </label>
                    <select class="form-select shadow-none">
                        <option value="1" selected>Tokyo</option>
                        <option value="2">New York</option>
                        <option value="3">London</option>
                        <option value="4">Beijing</option>
                        <option value="5">Toronto</option>
                        <option value="6">Chicago</option>
                        <option value="7">Hong Kong</option>
                        <option value="8">Los Angeles</option>
                        <option value="9">São Paulo</option>
                        <option value="10">Seoul</option>
                        <option value="11">Barcelona</option>
                        <option value="12">Istanbul</option>
                        <option value="13">Osaka</option>
                        <option value="14">Copenhagen</option>
                        <option value="15">Madrid</option>
                        <option value="16">Bangkok</option>
                        <option value="17">Dubai</option>
                        <option value="18">Melbourne</option>
                        <option value="19">Amsterdam</option>
                        <option value="20">Paris</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label>
                        Complete address
                    </label>
                    <input type="text" class="form-control shadow-none" placeholder="84 Wexham Road RH19 8EA, USA">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Latitude
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Longitude
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
        </div>
        <button type="submit" class="default-btn">
            Next
        </button>
    </form>
</div>