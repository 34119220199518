<app-navbar></app-navbar>

<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <h1>
                    Candidate Details
                </h1>
            </div>
            <div class="col-lg-4 col-md-12">
                <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-inline-block position-relative">
                        <a routerLink="/">
                            Home
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        <a routerLink="/candidates">
                            Candidates
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        Candidate Details
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Candidate Details Area -->
<div class="candidate-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                    <div class="d-sm-flex align-items-center">
                        <div class="image">
                            <img src="assets/images/candidates/candidate1.jpg" class="rounded-circle" alt="candidate-image">
                        </div>
                        <div class="content">
                            <h3 class="fw-semibold">
                                Donald Darnell
                            </h3>
                            <span class="designation d-block">
                                Software Engineer
                            </span>
                            <span class="location d-block">
                                <span>Location:</span> London
                            </span>
                        </div>
                    </div>
                    <div class="download-cv">
                        <a href="javascript:void(0);" class="default-btn">
                            Download CV
                        </a>
                    </div>
                </div>
                <div class="candidate-details-description">
                    <h3>
                        About me
                    </h3>
                    <p>
                        Enim urna pulvinar orci leo viverra. A augue nisl convallis amet pellentesque sollicitudin. Commodo volutpat euismod nec duis iaculis mattis quam diam. Sollicitudin eu mi aliquet at tellus sed pellentesque. Id non in pharetra massa urna ullamcorper tempor. Leo tempus pharetra mi mattis ut porttitor viverra. Suspendisse massa eros pulvinar eu tortor semper sit. In tempus euismod id scelerisque convallis praesent. Viverra tortor sit volutpat elementum nibh leo rhoncus viverra vestibulum. At non nunc fames vulputate nibh pharetra eu nulla aenean. Fusce pretium purus pellentesque faucibus sed neque diam a maecenas.
                    </p>
                    <h3>
                        Education
                    </h3>
                    <ul class="ps-0 list-unstyled">
                        <li class="position-relative">
                            <div class="number">
                                1
                            </div>
                            <h4>
                                Masters in Fine Arts
                            </h4>
                            <span class="d-block designation">
                                <span>Walters University</span> - (2020-2021)
                            </span>
                            <p>
                                Commodo volutpat euismod nec duis iaculis mattis quam diam. Sollicitudin eu mi aliquet at tellus sed pellentesque. Id non in pharetra massa urna ullamcorper tempor. Leo tempus pharetra mi mattis ut porttitor viverra. Suspendisse massa eros pulvinar eu tortor semper sit.
                            </p>
                        </li>
                        <li class="position-relative">
                            <div class="number">
                                2
                            </div>
                            <h4>
                                Bachelors in Fine Arts
                            </h4>
                            <span class="d-block designation">
                                <span>University of California</span> - (2016-2020)
                            </span>
                            <p>
                                Viverra tortor sit volutpat elementum nibh leo rhoncus viverra vestibulum. At non nunc fames vulputate nibh pharetra eu nulla aenean. Fusce pretium purus pellentesque faucibus sed neque diam a maecenas.
                            </p>
                        </li>
                    </ul>
                    <h3>
                        Background and expertise
                    </h3>
                    <ul class="ps-0 list-unstyled">
                        <li class="position-relative">
                            <div class="number">
                                1
                            </div>
                            <h4>
                                Sr. Software Engineer
                            </h4>
                            <span class="d-block designation">
                                <span>Jove</span> - (2020-2023)
                            </span>
                            <p>
                                Commodo volutpat euismod nec duis iaculis mattis quam diam. Sollicitudin eu mi aliquet at tellus sed pellentesque. Id non in pharetra massa urna ullamcorper tempor. Leo tempus pharetra mi mattis ut porttitor viverra. Suspendisse massa eros pulvinar eu tortor semper sit.
                            </p>
                        </li>
                        <li class="position-relative">
                            <div class="number">
                                2
                            </div>
                            <h4>
                                Product Designer
                            </h4>
                            <span class="d-block designation">
                                <span>Cirku</span> - (2018-2020)
                            </span>
                            <p>
                                Sollicitudin eu mi aliquet at tellus sed pellentesque. Id non in pharetra massa urna ullamcorper tempor. Leo tempus pharetra mi mattis ut porttitor viverra. Suspendisse massa eros pulvinar eu tortor semper sit. In tempus euismod id scelerisque convallis praesent.
                            </p>
                        </li>
                        <li class="position-relative">
                            <div class="number">
                                3
                            </div>
                            <h4>
                                Web Designer
                            </h4>
                            <span class="d-block designation">
                                <span>HiBootstrap</span> - (2016-2018)
                            </span>
                            <p>
                                Sollicitudin eu mi aliquet at tellus sed pellentesque. Id non in pharetra massa urna ullamcorper tempor. Leo tempus pharetra mi mattis ut porttitor viverra. Suspendisse massa eros pulvinar eu tortor semper sit. In tempus euismod id scelerisque convallis praesent.
                            </p>
                        </li>
                    </ul>
                    <h3>
                        Passion and future goals
                    </h3>
                    <p>
                        Enim urna pulvinar orci leo viverra. A augue nisl convallis amet pellentesque sollicitudin. Commodo volutpat euismod nec duis iaculis mattis quam diam. Sollicitudin eu mi aliquet at tellus sed pellentesque. Id non in pharetra massa urna ullamcorper tempor. Leo tempus pharetra mi mattis ut porttitor viverra. Suspendisse massa eros pulvinar eu tortor semper sit. In tempus euismod id scelerisque convallis praesent.
                    </p>
                    <p>
                        Viverra tortor sit volutpat elementum nibh leo rhoncus viverra vestibulum. At non nunc fames vulputate nibh pharetra eu nulla aenean. Fusce pretium purus pellentesque faucibus sed neque diam a maecenas.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="sidebar-area">
                    <div class="box">
                        <h3>
                            Contact information
                        </h3>
                        <ul class="contact-information ps-0 mb-0 list-unstyled">
                            <li>
                                <span class="fw-medium">Email:</span> <a href="mailto:hello@jove.com">hello&#64;jove.com</a>
                            </li>
                            <li>
                                <span class="fw-medium">Phone:</span> <a href="tel:+1 (514) 312-5678">+1 (514) 312-5678</a>
                            </li>
                            <li>
                                <span class="fw-medium">Offered Salary:</span> $50/ hour
                            </li>
                            <li>
                                <span class="fw-medium">Experience:</span> 4 Years
                            </li>
                            <li>
                                <span class="fw-medium">Gender:</span> Male
                            </li>
                            <li>
                                <span class="fw-medium">Age:</span> 30
                            </li>
                            <li>
                                <span class="fw-medium">Qualification:</span> Doctorate Degree
                            </li>
                            <li>
                                <span class="fw-medium">Languages:</span> English
                            </li>
                            <li>
                                <span class="fw-medium">Professional Skills:</span> Angular and TypeScript
                            </li>
                        </ul>
                    </div>
                    <div class="box">
                        <h3>
                            Social profiles
                        </h3>
                        <ul class="socials-link mb-0 ps-0 list-unstyled">
                            <li>
                                <a href="#" target="_blank" class="facebook d-flex align-items-center">
                                    <span class="d-block">
                                        <i class="ri-facebook-fill"></i>
                                    </span>
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="twitter d-flex align-items-center">
                                    <span class="d-block">
                                        <i class="ri-twitter-x-line"></i>
                                    </span>
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="linkedin d-flex align-items-center">
                                    <span class="d-block">
                                        <i class="ri-linkedin-fill"></i>
                                    </span>
                                    Linkedin
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="box">
                        <h3>
                            Download CV
                        </h3>
                        <div class="download-cv">
                            <a href="javascript:void(0);" class="d-block position-relative">
                                <span class="d-block">
                                    cv_Donald_Darnell
                                </span>
                                PDF
                                <i class="ri-file-download-line"></i>
                            </a>
                            <a href="javascript:void(0);" class="d-block position-relative">
                                <span class="d-block">
                                    cv_Donald_Darnell123
                                </span>
                                XML
                                <i class="ri-file-download-line"></i>
                            </a>
                        </div>
                    </div>
                    <div class="box">
                        <h3>
                            Contact with me
                        </h3>
                        <form>
                            <div class="form-group">
                                <label>
                                    Your name
                                </label>
                                <input type="text" class="form-control shadow-none">
                            </div>
                            <div class="form-group">
                                <label>
                                    Your Email
                                </label>
                                <input type="email" class="form-control shadow-none">
                            </div>
                            <div class="form-group">
                                <label>
                                    Your Message
                                </label>
                                <textarea cols="30" rows="5" class="form-control shadow-none"></textarea>
                            </div>
                            <button type="submit" class="default-btn">
                                Send Message
                            </button>
                        </form>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>

<app-subscribe></app-subscribe>

<app-footer></app-footer>

<ngx-scrolltop></ngx-scrolltop>