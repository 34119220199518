<aside class="widget-area">
    <div class="widget widget_search">
        <h3 class="widget-title fw-semibold">
            Search
        </h3>
        <form class="search-box position-relative">
            <input type="text" class="input-search" placeholder="Search....">
            <button type="submit">
                <i class="ri-search-line"></i>
            </button>
        </form>
    </div>
    <div class="widget widget_categories">
        <h3 class="widget-title fw-semibold">
            Categories
        </h3>
        <ul class="ps-0 mb-0 list-unstyled">
            <li>
                <a routerLink="/blog">
                    Career tips <span>(5)</span>
                </a>
            </li>
            <li>
                <a routerLink="/blog">
                    Latest news & advice <span>(4)</span>
                </a>
            </li>
            <li>
                <a routerLink="/blog">
                    Education <span>(1)</span>
                </a>
            </li>
            <li>
                <a routerLink="/blog">
                    Resume writing tips <span>(2)</span>
                </a>
            </li>
            <li>
                <a routerLink="/blog">
                    Interview <span>(4)</span>
                </a>
            </li>
            <li>
                <a routerLink="/blog">
                    Job seeking <span>(3)</span>
                </a>
            </li>
            <li>
                <a routerLink="/blog">
                    Skill <span>(2)</span>
                </a>
            </li>
        </ul>
    </div>
    <div class="widget widget_recent_news">
        <h3 class="widget-title fw-semibold">
            Recent news
        </h3>
        <ul class="ps-0 mb-0 list-unstyled">
            <li>
                <a routerLink="/blog-details" class="image">
                    <img src="assets/images/blogs/blog1.jpg" alt="blog-image">
                </a>
                <div class="info">
                    <span class="d-block">
                        10 Nov 2023
                    </span>
                    <h4 class="mb-0 fw-semibold">
                        <a routerLink="/blog-details">
                            Mastering the art of job board profile creation
                        </a>
                    </h4>
                </div>
            </li>
            <li>
                <a routerLink="/blog-details" class="image">
                    <img src="assets/images/blogs/blog2.jpg" alt="blog-image">
                </a>
                <div class="info">
                    <span class="d-block">
                        11 Nov 2023
                    </span>
                    <h4 class="mb-0 fw-semibold">
                        <a routerLink="/blog-details">
                            How job boards are shaping hiring trends
                        </a>
                    </h4>
                </div>
            </li>
            <li>
                <a routerLink="/blog-details" class="image">
                    <img src="assets/images/blogs/blog3.jpg" alt="blog-image">
                </a>
                <div class="info">
                    <span class="d-block">
                        12 Nov 2023
                    </span>
                    <h4 class="mb-0 fw-semibold">
                        <a routerLink="/blog-details">
                            Cracking the code of successful job applications
                        </a>
                    </h4>
                </div>
            </li>
        </ul>
    </div>
    <div class="widget widget_tags">
        <h3 class="widget-title fw-semibold">
            Tags
        </h3>
        <div class="tags">
            <a routerLink="/blog" class="d-inline-block">
                Jobs
            </a>
            <a routerLink="/blog" class="d-inline-block">
                Career
            </a>
            <a routerLink="/blog" class="d-inline-block">
                Skill
            </a>
            <a routerLink="/blog" class="d-inline-block">
                Education
            </a>
            <a routerLink="/blog" class="d-inline-block">
                Interview
            </a>
            <a routerLink="/blog" class="d-inline-block">
                Resume
            </a>
            <a routerLink="/blog" class="d-inline-block">
                Traffic
            </a>
            <a routerLink="/blog" class="d-inline-block">
                Advice
            </a>
            <a routerLink="/blog" class="d-inline-block">
                Company
            </a>
            <a routerLink="/blog" class="d-inline-block">
                Tips
            </a>
        </div>
    </div>
</aside>