<div class="partners-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-xxl-2 col-lg-3" data-aos="fade-in" data-aos-duration="500">
                <div class="section-title text-center text-lg-start">
                    <h2>We worked with:</h2>
                </div>
            </div>
            <div class="col-xxl-10 col-lg-9" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <owl-carousel-o [options]="partnersSlides">
                    <ng-template carouselSlide>
                        <img src="assets/images/partners/partner1.png" alt="company-image">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/images/partners/partner2.png" alt="company-image">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/images/partners/partner3.png" alt="company-image">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/images/partners/partner4.png" alt="company-image">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/images/partners/partner5.png" alt="company-image">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/images/partners/partner6.png" alt="company-image">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/images/partners/partner7.png" alt="company-image">
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>