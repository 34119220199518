/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CmsPage } from '../models/cms-page';

@Injectable({
  providedIn: 'root',
})
export class CmsPageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getpagesList
   */
  static readonly GetpagesListPath = '/cms/pages';

  /**
   * Get list of pages.
   *
   * Returns list of pages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getpagesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getpagesList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsPage>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsPageService.GetpagesListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<CmsPage>;
        }>;
      })
    );
  }

  /**
   * Get list of pages.
   *
   * Returns list of pages
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getpagesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getpagesList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsPage>;
}> {

    return this.getpagesList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsPage>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsPage>;
})
    );
  }

  /**
   * Path part for operation storePage
   */
  static readonly StorePagePath = '/cms/pages';

  /**
   * Store new Page.
   *
   * Returns Page data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storePage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storePage$Response(params: {
    body: CmsPage
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPage;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsPageService.StorePagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: CmsPage;
        }>;
      })
    );
  }

  /**
   * Store new Page.
   *
   * Returns Page data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storePage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storePage(params: {
    body: CmsPage
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPage;
}> {

    return this.storePage$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPage;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPage;
})
    );
  }

  /**
   * Path part for operation getPageId
   */
  static readonly GetPageIdPath = '/cms/pages/{id}';

  /**
   * Get  Page by id.
   *
   * Returns Page data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPageId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageId$Response(params: {

    /**
     * id of Page
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPage;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsPageService.GetPageIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: CmsPage;
        }>;
      })
    );
  }

  /**
   * Get  Page by id.
   *
   * Returns Page data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPageId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageId(params: {

    /**
     * id of Page
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPage;
}> {

    return this.getPageId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPage;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPage;
})
    );
  }

  /**
   * Path part for operation putPage
   */
  static readonly PutPagePath = '/cms/pages/{id}';

  /**
   * Update Page.
   *
   * Returns Page data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPage$Response(params: {

    /**
     * id of Page
     */
    id: string;
    body: CmsPage
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPage;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsPageService.PutPagePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: CmsPage;
        }>;
      })
    );
  }

  /**
   * Update Page.
   *
   * Returns Page data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPage(params: {

    /**
     * id of Page
     */
    id: string;
    body: CmsPage
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPage;
}> {

    return this.putPage$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPage;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPage;
})
    );
  }

  /**
   * Path part for operation deletePage
   */
  static readonly DeletePagePath = '/cms/pages/{id}';

  /**
   * Store new Page.
   *
   * Returns Page data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePage()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePage$Response(params: {

    /**
     * id of Page
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsPageService.DeletePagePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Page.
   *
   * Returns Page data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePage(params: {

    /**
     * id of Page
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deletePage$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
