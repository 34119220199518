/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminMasterJenis } from '../models/admin-master-jenis';

@Injectable({
  providedIn: 'root',
})
export class AdminMasterJenisService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getadminMasterJenisList
   */
  static readonly GetadminMasterJenisListPath = '/admin/masterJenis';

  /**
   * Get list of masterJenis.
   *
   * Returns list of masterJenis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminMasterJenisList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminMasterJenisList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterJenis>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterJenisService.GetadminMasterJenisListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminMasterJenis>;
        }>;
      })
    );
  }

  /**
   * Get list of masterJenis.
   *
   * Returns list of masterJenis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminMasterJenisList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminMasterJenisList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterJenis>;
}> {

    return this.getadminMasterJenisList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterJenis>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterJenis>;
})
    );
  }

  /**
   * Path part for operation storeadminMasterJenis
   */
  static readonly StoreadminMasterJenisPath = '/admin/masterJenis';

  /**
   * Store new Master_jenis.
   *
   * Returns Master_jenis data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeadminMasterJenis()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminMasterJenis$Response(params: {
    body: AdminMasterJenis
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterJenis;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterJenisService.StoreadminMasterJenisPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterJenis;
        }>;
      })
    );
  }

  /**
   * Store new Master_jenis.
   *
   * Returns Master_jenis data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeadminMasterJenis$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminMasterJenis(params: {
    body: AdminMasterJenis
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterJenis;
}> {

    return this.storeadminMasterJenis$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterJenis;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterJenis;
})
    );
  }

  /**
   * Path part for operation getadminMasterJenisId
   */
  static readonly GetadminMasterJenisIdPath = '/admin/masterJenis/{id}';

  /**
   * Get  Master_jenis by id.
   *
   * Returns Master_jenis data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminMasterJenisId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminMasterJenisId$Response(params: {

    /**
     * id of Master_jenis
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterJenis;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterJenisService.GetadminMasterJenisIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterJenis;
        }>;
      })
    );
  }

  /**
   * Get  Master_jenis by id.
   *
   * Returns Master_jenis data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminMasterJenisId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminMasterJenisId(params: {

    /**
     * id of Master_jenis
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterJenis;
}> {

    return this.getadminMasterJenisId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterJenis;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterJenis;
})
    );
  }

  /**
   * Path part for operation putadminMasterJenis
   */
  static readonly PutadminMasterJenisPath = '/admin/masterJenis/{id}';

  /**
   * Update Master_jenis.
   *
   * Returns Master_jenis data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putadminMasterJenis()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminMasterJenis$Response(params: {

    /**
     * id of Master_jenis
     */
    id: string;
    body: AdminMasterJenis
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterJenis;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterJenisService.PutadminMasterJenisPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterJenis;
        }>;
      })
    );
  }

  /**
   * Update Master_jenis.
   *
   * Returns Master_jenis data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putadminMasterJenis$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminMasterJenis(params: {

    /**
     * id of Master_jenis
     */
    id: string;
    body: AdminMasterJenis
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterJenis;
}> {

    return this.putadminMasterJenis$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterJenis;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterJenis;
})
    );
  }

  /**
   * Path part for operation deleteadminMasterJenis
   */
  static readonly DeleteadminMasterJenisPath = '/admin/masterJenis/{id}';

  /**
   * Store new Master_jenis.
   *
   * Returns Master_jenis data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteadminMasterJenis()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminMasterJenis$Response(params: {

    /**
     * id of Master_jenis
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterJenisService.DeleteadminMasterJenisPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Master_jenis.
   *
   * Returns Master_jenis data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteadminMasterJenis$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminMasterJenis(params: {

    /**
     * id of Master_jenis
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteadminMasterJenis$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
