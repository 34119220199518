/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientHayatiByTaksa } from '../models/client-hayati-by-taksa';

@Injectable({
  providedIn: 'root',
})
export class ClientHayatiByTaksaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientHayatiByTaksasList
   */
  static readonly GetclientHayatiByTaksasListPath = '/client/hayatiByTaksas';

  /**
   * Get list of hayatiByTaksas.
   *
   * Returns list of hayatiByTaksas
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientHayatiByTaksasList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByTaksasList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByTaksa>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByTaksaService.GetclientHayatiByTaksasListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientHayatiByTaksa>;
        }>;
      })
    );
  }

  /**
   * Get list of hayatiByTaksas.
   *
   * Returns list of hayatiByTaksas
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientHayatiByTaksasList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByTaksasList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByTaksa>;
}> {

    return this.getclientHayatiByTaksasList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByTaksa>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByTaksa>;
})
    );
  }

  /**
   * Path part for operation storeclientHayatiByTaksa
   */
  static readonly StoreclientHayatiByTaksaPath = '/client/hayatiByTaksas';

  /**
   * Store new Hayati_by_taksa.
   *
   * Returns Hayati_by_taksa data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeclientHayatiByTaksa()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientHayatiByTaksa$Response(params: {
    body: ClientHayatiByTaksa
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByTaksa;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByTaksaService.StoreclientHayatiByTaksaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientHayatiByTaksa;
        }>;
      })
    );
  }

  /**
   * Store new Hayati_by_taksa.
   *
   * Returns Hayati_by_taksa data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeclientHayatiByTaksa$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientHayatiByTaksa(params: {
    body: ClientHayatiByTaksa
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByTaksa;
}> {

    return this.storeclientHayatiByTaksa$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByTaksa;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByTaksa;
})
    );
  }

  /**
   * Path part for operation getclientHayatiByTaksaId
   */
  static readonly GetclientHayatiByTaksaIdPath = '/client/hayatiByTaksas/{id}';

  /**
   * Get  Hayati_by_taksa by id.
   *
   * Returns Hayati_by_taksa data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientHayatiByTaksaId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByTaksaId$Response(params: {

    /**
     * id of Hayati_by_taksa
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByTaksa;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByTaksaService.GetclientHayatiByTaksaIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientHayatiByTaksa;
        }>;
      })
    );
  }

  /**
   * Get  Hayati_by_taksa by id.
   *
   * Returns Hayati_by_taksa data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientHayatiByTaksaId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByTaksaId(params: {

    /**
     * id of Hayati_by_taksa
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByTaksa;
}> {

    return this.getclientHayatiByTaksaId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByTaksa;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByTaksa;
})
    );
  }

  /**
   * Path part for operation putclientHayatiByTaksa
   */
  static readonly PutclientHayatiByTaksaPath = '/client/hayatiByTaksas/{id}';

  /**
   * Update Hayati_by_taksa.
   *
   * Returns Hayati_by_taksa data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putclientHayatiByTaksa()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientHayatiByTaksa$Response(params: {

    /**
     * id of Hayati_by_taksa
     */
    id: string;
    body: ClientHayatiByTaksa
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByTaksa;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByTaksaService.PutclientHayatiByTaksaPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientHayatiByTaksa;
        }>;
      })
    );
  }

  /**
   * Update Hayati_by_taksa.
   *
   * Returns Hayati_by_taksa data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putclientHayatiByTaksa$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientHayatiByTaksa(params: {

    /**
     * id of Hayati_by_taksa
     */
    id: string;
    body: ClientHayatiByTaksa
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByTaksa;
}> {

    return this.putclientHayatiByTaksa$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByTaksa;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByTaksa;
})
    );
  }

  /**
   * Path part for operation deleteclientHayatiByTaksa
   */
  static readonly DeleteclientHayatiByTaksaPath = '/client/hayatiByTaksas/{id}';

  /**
   * Store new Hayati_by_taksa.
   *
   * Returns Hayati_by_taksa data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteclientHayatiByTaksa()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientHayatiByTaksa$Response(params: {

    /**
     * id of Hayati_by_taksa
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByTaksaService.DeleteclientHayatiByTaksaPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Hayati_by_taksa.
   *
   * Returns Hayati_by_taksa data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteclientHayatiByTaksa$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientHayatiByTaksa(params: {

    /**
     * id of Hayati_by_taksa
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteclientHayatiByTaksa$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
