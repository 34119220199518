<!-- Title -->
<div class="breadcrumb-title">
    <h1 class="mb-0 fw-semibold">
        Message
    </h1>
</div>

<!-- Messages -->
<div class="row">
    <div class="col-lg-4">
        <div class="jove-card">
            <form class="search-box position-relative">
                <input type="text" class="input-search" placeholder="Search....">
                <button type="submit">
                    <i class="ri-search-line"></i>
                </button>
            </form>
            <div class="overflow-scroll">
                <span class="sub-title mt-0 d-block position-relative">
                    <i class="ri-pushpin-line"></i> Pinned
                </span>
                <ul class="sidebar-list ps-0 list-unstyled mb-0">
                    <li class="position-relative d-flex align-items-center justify-content-between">
                        <div class="content d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="assets/images/users/user1.png" width="45" class="rounded-circle" alt="user-image">
                                <span class="active-status"></span>
                            </div>
                            <div class="title">
                                <h5 class="fw-semibold">Laurent Perrier</h5>
                                <span class="d-block green-color">Typing...</span>
                            </div>
                        </div>
                        <div class="info text-end">
                            <span class="time d-block">4:30 PM</span>
                            <span class="badge">2</span>
                        </div>
                    </li>
                    <li class="position-relative d-flex align-items-center justify-content-between">
                        <div class="content d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="assets/images/users/user2.png" width="45" class="rounded-circle" alt="user-image">
                                <span class="active-status"></span>
                            </div>
                            <div class="title">
                                <h5 class="fw-semibold">Nunez Faulkner</h5>
                                <span class="d-block">Hello everyone ...</span>
                            </div>
                        </div>
                        <div class="info text-end">
                            <span class="time d-block">9:36 AM</span>
                            <i class="ri-check-double-line"></i>
                        </div>
                    </li>
                    <li class="position-relative d-flex align-items-center justify-content-between">
                        <div class="content d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="assets/images/users/user3.png" width="45" class="rounded-circle" alt="user-image">
                                <span class="active-status off"></span>
                            </div>
                            <div class="title">
                                <h5 class="fw-semibold">Bernard Langley</h5>
                                <span class="d-block">That cool, go for it...</span>
                            </div>
                        </div>
                        <div class="info text-end">
                            <span class="time d-block">7:18 PM</span>
                            <i class="ri-check-line"></i>
                        </div>
                    </li>
                    <li class="position-relative d-flex align-items-center justify-content-between">
                        <div class="content d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="assets/images/users/user4.png" width="45" class="rounded-circle" alt="user-image">
                                <span class="active-status"></span>
                            </div>
                            <div class="title">
                                <h5 class="fw-semibold">Edwards Mckenz</h5>
                                <span class="d-block">Great ! 🔥</span>
                            </div>
                        </div>
                        <div class="info text-end">
                            <span class="time d-block">08:30 PM</span>
                            <i class="ri-check-line"></i>
                        </div>
                    </li>
                </ul>
                <span class="sub-title d-block position-relative">
                    <i class="ri-message-3-line"></i> All Message
                </span>
                <ul class="sidebar-list ps-0 list-unstyled mb-0">
                    <li class="position-relative d-flex align-items-center justify-content-between">
                        <div class="content d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="assets/images/users/user5.png" width="45" class="rounded-circle" alt="user-image">
                                <span class="active-status"></span>
                            </div>
                            <div class="title">
                                <h5 class="fw-semibold">Elsie Melendez</h5>
                                <span class="d-block green-color">Typing...</span>
                            </div>
                        </div>
                        <div class="info text-end">
                            <span class="time d-block">4:30 PM</span>
                            <span class="badge">3</span>
                        </div>
                    </li>
                    <li class="position-relative d-flex align-items-center justify-content-between">
                        <div class="content d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="assets/images/users/user6.png" width="45" class="rounded-circle" alt="user-image">
                                <span class="active-status"></span>
                            </div>
                            <div class="title">
                                <h5 class="fw-semibold">Mcleod Wagner</h5>
                                <span class="d-block">What are you...</span>
                            </div>
                        </div>
                        <div class="info text-end">
                            <span class="time d-block">9:36 AM</span>
                            <i class="ri-check-double-line"></i>
                        </div>
                    </li>
                    <li class="position-relative d-flex align-items-center justify-content-between">
                        <div class="content d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="assets/images/users/user7.png" width="45" class="rounded-circle" alt="user-image">
                                <span class="active-status off"></span>
                            </div>
                            <div class="title">
                                <h5 class="fw-semibold">Alina Smith</h5>
                                <span class="d-block"><i class="ri-mic-line"></i> Voice message</span>
                            </div>
                        </div>
                        <div class="info text-end">
                            <span class="time d-block">7:18 PM</span>
                            <i class="ri-check-line"></i>
                        </div>
                    </li>
                    <li class="position-relative d-flex align-items-center justify-content-between">
                        <div class="content d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="assets/images/users/user8.jpg" width="45" class="rounded-circle" alt="user-image">
                            </div>
                            <div class="title">
                                <h5 class="fw-semibold">Silva Foster</h5>
                                <span class="d-block"><i class="ri-mic-line"></i> Voice message</span>
                            </div>
                        </div>
                        <div class="info text-end">
                            <span class="time d-block">08:30 PM</span>
                        </div>
                    </li>
                    <li class="position-relative d-flex align-items-center justify-content-between">
                        <div class="content d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="assets/images/users/user9.jpg" width="45" class="rounded-circle" alt="user-image">
                            </div>
                            <div class="title">
                                <h5 class="fw-semibold">Joseph Strick</h5>
                                <span class="d-block">Cool ! 🔥</span>
                            </div>
                        </div>
                        <div class="info text-end">
                            <span class="time d-block">08:00 PM</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-lg-8">
        <div class="jove-card chat-card">
            <div class="chat-header d-md-flex align-items-center justify-content-between">
                <div class="user d-flex align-items-center">
                    <img src="assets/images/users/user1.png" width="50" class="rounded-circle" alt="user-image">
                    <div class="title">
                        <h5 class="fw-semibold">Laurent Perrier</h5>
                        <span class="d-block active position-relative">Active now</span>
                        <!-- <span class="d-block deactive position-relative">Active now</span> -->
                    </div>
                </div>
                <a href="javascript:void(0);" class="delete-conversation d-inline-block">
                    Delete conversation
                </a>
            </div>
            <div class="chat-body">
                <ul class="ps-0 list-unstyled mb-0">
                    <li class="position-relative">
                        <img src="assets/images/users/user1.png" width="50" class="rounded-circle user" alt="user-image">
                        <div class="message">
                            <div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra ligula non varius curabitur etiam malesuada. Congue eget luctus aliquet consectetur.</p>
                            </div>
                        </div>
                        <span class="time d-block">19:04</span>
                    </li>
                    <li class="position-relative right">
                        <img src="assets/images/users/user2.png" width="50" class="rounded-circle user" alt="user-image">
                        <div class="message">
                            <div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </div>
                        </div>
                        <span class="time d-block">12:12</span>
                    </li>
                    <li class="position-relative">
                        <img src="assets/images/users/user1.png" width="50" class="rounded-circle user" alt="user-image">
                        <div class="message">
                            <div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra ligula non varius.</p>
                            </div>
                            <div>
                                <p>Lorem ipsum dolor sit amet. 🔥</p>
                            </div>
                        </div>
                        <span class="time d-block">19:04</span>
                    </li>
                    <li class="position-relative right">
                        <img src="assets/images/users/user2.png" width="50" class="rounded-circle user" alt="user-image">
                        <div class="message">
                            <div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit.</p>
                            </div>
                            <div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </div>
                        </div>
                        <span class="time d-block">12:12</span>
                    </li>
                    <li class="position-relative">
                        <img src="assets/images/users/user1.png" width="50" class="rounded-circle user" alt="user-image">
                        <div class="message">
                            <div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra ligula non varius curabitur etiam malesuada. Congue eget luctus aliquet consectetur.</p>
                            </div>
                        </div>
                        <span class="time d-block">19:04</span>
                    </li>
                    <li class="position-relative right">
                        <img src="assets/images/users/user2.png" width="50" class="rounded-circle user" alt="user-image">
                        <div class="message">
                            <div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </div>
                        </div>
                        <span class="time d-block">12:12</span>
                    </li>
                </ul>
            </div>
            <div class="chat-footer d-md-flex align-items-center">
                <div class="list">
                    <button class="bg-transparent p-0 border-0" type="button">
                        <i class="ri-emotion-line"></i>
                    </button>
                    <button class="bg-transparent p-0 border-0" type="button">
                        <i class="ri-link-m"></i>
                    </button>
                </div>
                <form class="position-relative">
                    <input type="text" class="input-text" placeholder="Type something...">
                    <button type="submit"><i class="ri-send-plane-fill"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>