/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientPage } from '../models/client-page';

@Injectable({
  providedIn: 'root',
})
export class ClientPageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientgetpagesList
   */
  static readonly ClientgetpagesListPath = '/client/pages';

  /**
   * Get list of pages.
   *
   * Returns list of pages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientgetpagesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetpagesList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPage>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPageService.ClientgetpagesListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientPage>;
        }>;
      })
    );
  }

  /**
   * Get list of pages.
   *
   * Returns list of pages
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientgetpagesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetpagesList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPage>;
}> {

    return this.clientgetpagesList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPage>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientPage>;
})
    );
  }

  /**
   * Path part for operation clientstorePage
   */
  static readonly ClientstorePagePath = '/client/pages';

  /**
   * Store new Page.
   *
   * Returns Page data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientstorePage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientstorePage$Response(params: {
    body: ClientPage
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPage;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPageService.ClientstorePagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPage;
        }>;
      })
    );
  }

  /**
   * Store new Page.
   *
   * Returns Page data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientstorePage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientstorePage(params: {
    body: ClientPage
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPage;
}> {

    return this.clientstorePage$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPage;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPage;
})
    );
  }

  /**
   * Path part for operation clientgetPageId
   */
  static readonly ClientgetPageIdPath = '/client/pages/{id}';

  /**
   * Get  Page by id.
   *
   * Returns Page data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientgetPageId()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetPageId$Response(params: {

    /**
     * id of Page
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPage;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientPageService.ClientgetPageIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientPage;
        }>;
      })
    );
  }

  /**
   * Get  Page by id.
   *
   * Returns Page data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientgetPageId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetPageId(params: {

    /**
     * id of Page
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPage;
}> {

    return this.clientgetPageId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPage;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientPage;
})
    );
  }

}
