import { Component } from '@angular/core';

@Component({
  selector: 'app-cd-change-password',
  templateUrl: './cd-change-password.component.html',
  styleUrls: ['./cd-change-password.component.scss']
})
export class CdChangePasswordComponent {

}
