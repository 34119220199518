<!-- Title -->
<div class="breadcrumb-title">
    <h1 class="mb-0 fw-semibold">
        Change Password
    </h1>
</div>

<!-- Change Password -->
<div class="jove-card">
    <form>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Old password
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        New password
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label>
                        Confirm password
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
        </div>
        <button type="submit" class="default-btn">
            Update
        </button>
    </form>
</div>