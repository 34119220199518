<!-- Title -->
<div class="breadcrumb-title">
    <h1 class="mb-0 fw-semibold">
        Hello, Doca!
    </h1>
</div>

<!-- Stats -->
<div class="row">
    <div class="col-xxl-3 col-xl-6 col-lg-3 col-6">
        <div class="single-stats-box d-md-flex align-items-center">
            <div class="icon">
                <i class="ri-briefcase-line"></i>
            </div>
            <div>
                <h3>
                    70
                </h3>
                <span class="d-block">
                    Posted jobs
                </span>
            </div>
        </div>
    </div>
    <div class="col-xxl-3 col-xl-6 col-lg-3 col-6">
        <div class="single-stats-box d-md-flex align-items-center">
            <div class="icon">
                <i class="ri-article-line"></i>
            </div>
            <div>
                <h3>
                    2839
                </h3>
                <span class="d-block">
                    Application
                </span>
            </div>
        </div>
    </div>
    <div class="col-xxl-3 col-xl-6 col-lg-3 col-6">
        <div class="single-stats-box d-md-flex align-items-center">
            <div class="icon">
                <i class="ri-message-2-line"></i>
            </div>
            <div>
                <h3>
                    50
                </h3>
                <span class="d-block">
                    Messages
                </span>
            </div>
        </div>
    </div>
    <div class="col-xxl-3 col-xl-6 col-lg-3 col-6">
        <div class="single-stats-box d-md-flex align-items-center">
            <div class="icon">
                <i class="ri-bookmark-line"></i>
            </div>
            <div>
                <h3>
                    44
                </h3>
                <span class="d-block">
                    Shortlist
                </span>
            </div>
        </div>
    </div>
</div>

<!-- Profile Views & Notifications -->
<div class="row">
    <div class="col-xxl-5 col-md-12">
        <div class="jove-card">
            <h3 class="title">
                Notifications
            </h3>
            <ul class="mb-0 list-unstyled ps-0 notifications-list">
                <li class="position-relative">
                    <div class="icon">
                        <i class="ri-briefcase-line"></i>
                    </div>
                    <span class="fw-semibold">Donald Darnell</span> applied for a job <span class="color">Software Engineer</span>
                </li>
                <li class="position-relative">
                    <div class="icon">
                        <i class="ri-briefcase-line"></i>
                    </div>
                    <span class="fw-semibold">Olivie Riquier</span> applied for a job <span class="color">Accountant</span>
                </li>
                <li class="position-relative color">
                    <div class="icon">
                        <i class="ri-briefcase-line"></i>
                    </div>
                    <span class="fw-semibold">Edwin Lloyd</span> applied for a job <span class="color">Data Scientist</span>
                </li>
                <li class="position-relative">
                    <div class="icon">
                        <i class="ri-briefcase-line"></i>
                    </div>
                    <span class="fw-semibold">Bryant Hills</span> applied for a job <span class="color">UI/UX Designer</span>
                </li>
                <li class="position-relative color">
                    <div class="icon">
                        <i class="ri-briefcase-line"></i>
                    </div>
                    <span class="fw-semibold">Melissa Rodgers</span> applied for a job <span class="color">Pharmacist</span>
                </li>
                <li class="position-relative">
                    <div class="icon">
                        <i class="ri-briefcase-line"></i>
                    </div>
                    <span class="fw-semibold">Jeanne Richardson</span> applied for a job <span class="color">Marketing Manager</span>
                </li>
                <li class="position-relative">
                    <div class="icon">
                        <i class="ri-briefcase-line"></i>
                    </div>
                    <span class="fw-semibold">Bruce Saunders</span> applied for a job <span class="color">Systems Analyst</span>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-xxl-7 col-md-12">
        <div class="jove-card">
            <h3 class="title">
                Profile views
            </h3>
            <div class="chart">
                <apx-chart
                    [series]="chartOptions.series!"
                    [chart]="chartOptions.chart!"
                    [xaxis]="chartOptions.xaxis!"
                    [dataLabels]="chartOptions.dataLabels!"
                    [grid]="chartOptions.grid!"
                    [yaxis]="chartOptions.yaxis!"
                    [stroke]="chartOptions.stroke!"
                    [colors]="chartOptions.colors!"
                ></apx-chart>
            </div>
        </div>
    </div>
</div>

<!-- Recent Applicants -->
<div class="jove-card">
    <h3 class="title">
        Recent applicants
    </h3>
    <div class="row recent-applicants">
        <div class="col-xxl-6">
            <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                <div class="d-sm-flex align-items-center">
                    <div class="image">
                        <img src="assets/images/candidates/candidate1.jpg" class="rounded-circle" alt="candidate-image">
                    </div>
                    <div class="content">
                        <h3 class="fw-semibold">
                            <a routerLink="/candidate-details">
                                Donald Darnell
                            </a>
                        </h3>
                        <span class="designation d-block">
                            Software Engineer
                        </span>
                        <span class="location d-block">
                            <span>Location:</span> London
                        </span>
                    </div>
                </div>
                <div class="view-profile d-sm-flex align-items-center">
                    <span class="d-block">
                        $50/ hour
                    </span>
                    <ul class="ps-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-eye-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-check-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-close-line"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-xxl-6">
            <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                <div class="d-sm-flex align-items-center">
                    <div class="image">
                        <img src="assets/images/candidates/candidate2.jpg" class="rounded-circle" alt="candidate-image">
                    </div>
                    <div class="content">
                        <h3 class="fw-semibold">
                            <a routerLink="/candidate-details">
                                Olivie Riquier
                            </a>
                        </h3>
                        <span class="designation d-block">
                            Accountant
                        </span>
                        <span class="location d-block">
                            <span>Location:</span> Chicago
                        </span>
                    </div>
                </div>
                <div class="view-profile d-sm-flex align-items-center">
                    <span class="d-block">
                        $54/ hour
                    </span>
                    <ul class="ps-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-eye-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-check-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-close-line"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-xxl-6">
            <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                <div class="d-sm-flex align-items-center">
                    <div class="image">
                        <img src="assets/images/candidates/candidate3.jpg" class="rounded-circle" alt="candidate-image">
                    </div>
                    <div class="content">
                        <h3 class="fw-semibold">
                            <a routerLink="/candidate-details">
                                Edwin Lloyd
                            </a>
                        </h3>
                        <span class="designation d-block">
                            Data Scientist
                        </span>
                        <span class="location d-block">
                            <span>Location:</span> Dallas
                        </span>
                    </div>
                </div>
                <div class="view-profile d-sm-flex align-items-center">
                    <span class="d-block">
                        $99/ hour
                    </span>
                    <ul class="ps-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-eye-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-check-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-close-line"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-xxl-6">
            <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                <div class="d-sm-flex align-items-center">
                    <div class="image">
                        <img src="assets/images/candidates/candidate4.jpg" class="rounded-circle" alt="candidate-image">
                    </div>
                    <div class="content">
                        <h3 class="fw-semibold">
                            <a routerLink="/candidate-details">
                                Bryant Hills
                            </a>
                        </h3>
                        <span class="designation d-block">
                            UI/UX Designer
                        </span>
                        <span class="location d-block">
                            <span>Location:</span> Houston
                        </span>
                    </div>
                </div>
                <div class="view-profile d-sm-flex align-items-center">
                    <span class="d-block">
                        $35/ hour
                    </span>
                    <ul class="ps-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-eye-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-check-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-close-line"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-xxl-6">
            <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                <div class="d-sm-flex align-items-center">
                    <div class="image">
                        <img src="assets/images/candidates/candidate5.jpg" class="rounded-circle" alt="candidate-image">
                    </div>
                    <div class="content">
                        <h3 class="fw-semibold">
                            <a routerLink="/candidate-details">
                                Melissa Rodgers
                            </a>
                        </h3>
                        <span class="designation d-block">
                            Pharmacist
                        </span>
                        <span class="location d-block">
                            <span>Location:</span> Los Angeles
                        </span>
                    </div>
                </div>
                <div class="view-profile d-sm-flex align-items-center">
                    <span class="d-block">
                        $45/ hour
                    </span>
                    <ul class="ps-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-eye-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-check-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-close-line"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-xxl-6">
            <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                <div class="d-sm-flex align-items-center">
                    <div class="image">
                        <img src="assets/images/candidates/candidate6.jpg" class="rounded-circle" alt="candidate-image">
                    </div>
                    <div class="content">
                        <h3 class="fw-semibold">
                            <a routerLink="/candidate-details">
                                Jeanne Richardson
                            </a>
                        </h3>
                        <span class="designation d-block">
                            Marketing Manager
                        </span>
                        <span class="location d-block">
                            <span>Location:</span> New York
                        </span>
                    </div>
                </div>
                <div class="view-profile d-sm-flex align-items-center">
                    <span class="d-block">
                        $25/ hour
                    </span>
                    <ul class="ps-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-eye-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-check-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-close-line"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-xxl-6">
            <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                <div class="d-sm-flex align-items-center">
                    <div class="image">
                        <img src="assets/images/candidates/candidate7.jpg" class="rounded-circle" alt="candidate-image">
                    </div>
                    <div class="content">
                        <h3 class="fw-semibold">
                            <a routerLink="/candidate-details">
                                Bruce Saunders
                            </a>
                        </h3>
                        <span class="designation d-block">
                            Systems Analyst
                        </span>
                        <span class="location d-block">
                            <span>Location:</span> Philadelphia
                        </span>
                    </div>
                </div>
                <div class="view-profile d-sm-flex align-items-center">
                    <span class="d-block">
                        $73/ hour
                    </span>
                    <ul class="ps-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-eye-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-check-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-close-line"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-xxl-6">
            <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                <div class="d-sm-flex align-items-center">
                    <div class="image">
                        <img src="assets/images/candidates/candidate8.jpg" class="rounded-circle" alt="candidate-image">
                    </div>
                    <div class="content">
                        <h3 class="fw-semibold">
                            <a routerLink="/candidate-details">
                                Marilyn Graves
                            </a>
                        </h3>
                        <span class="designation d-block">
                            Tax Consultant
                        </span>
                        <span class="location d-block">
                            <span>Location:</span> London
                        </span>
                    </div>
                </div>
                <div class="view-profile d-sm-flex align-items-center">
                    <span class="d-block">
                        $60/ hour
                    </span>
                    <ul class="ps-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-eye-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-check-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-close-line"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-xxl-6">
            <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                <div class="d-sm-flex align-items-center">
                    <div class="image">
                        <img src="assets/images/candidates/candidate9.jpg" class="rounded-circle" alt="candidate-image">
                    </div>
                    <div class="content">
                        <h3 class="fw-semibold">
                            <a routerLink="/candidate-details">
                                William Killion
                            </a>
                        </h3>
                        <span class="designation d-block">
                            SEO Specialist
                        </span>
                        <span class="location d-block">
                            <span>Location:</span> London
                        </span>
                    </div>
                </div>
                <div class="view-profile d-sm-flex align-items-center">
                    <span class="d-block">
                        $70/ hour
                    </span>
                    <ul class="ps-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-eye-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-check-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-close-line"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-xxl-6">
            <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                <div class="d-sm-flex align-items-center">
                    <div class="image">
                        <img src="assets/images/candidates/candidate10.jpg" class="rounded-circle" alt="candidate-image">
                    </div>
                    <div class="content">
                        <h3 class="fw-semibold">
                            <a routerLink="/candidate-details">
                                Rebekah Prior
                            </a>
                        </h3>
                        <span class="designation d-block">
                            Content Writer
                        </span>
                        <span class="location d-block">
                            <span>Location:</span> San Antonio
                        </span>
                    </div>
                </div>
                <div class="view-profile d-sm-flex align-items-center">
                    <span class="d-block">
                        $45/ hour
                    </span>
                    <ul class="ps-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-eye-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-check-line"></i>
                            </button>
                        </li>
                        <li class="d-inline-block">
                            <button type="button" class="d-inline-block rounded-circle">
                                <i class="ri-close-line"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>