<app-navbar></app-navbar>

<app-hometwo-banner></app-hometwo-banner>

<app-about-us></app-about-us>

<app-categories></app-categories>

<app-features></app-features>

<app-companies></app-companies>

<app-popular-jobs></app-popular-jobs>

<app-why-choose-us></app-why-choose-us>

<app-how-jove-works></app-how-jove-works>

<app-testimonials></app-testimonials>

<app-funfacts></app-funfacts>

<app-talented-experts></app-talented-experts>

<app-faq></app-faq>

<app-download-app></app-download-app>

<app-blog></app-blog>

<app-subscribe></app-subscribe>

<app-footer></app-footer>

<ngx-scrolltop></ngx-scrolltop>