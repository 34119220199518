<!-- Title -->
<div class="breadcrumb-title">
    <h1 class="mb-0 fw-semibold">
        Company Profile
    </h1>
</div>

<!-- Company Profile -->
<div class="jove-card">
    <h3 class="title">
        My Profile
    </h3>
    <form>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group upload-photo">
                    <label>
                        Upload new photo
                    </label>
                    <input type="file" id="img" name="img" accept="image/*">
                    <p>Maximum file size: 2 MB</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Company name (optional)
                    </label>
                    <input type="text" class="form-control shadow-none" value="Doca">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Email address
                    </label>
                    <input type="email" class="form-control shadow-none" value="hello@doca.com">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Phone
                    </label>
                    <input type="text" class="form-control shadow-none" value="+1 (514) 312-5678">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Website
                    </label>
                    <input type="text" class="form-control shadow-none" value="https://www.doca.com/">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Est. since
                    </label>
                    <input type="text" class="form-control shadow-none" value="20 Oct 2008">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Team size
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>10 - 20</option>
                        <option value="1">20 - 30</option>
                        <option value="2">30 - 40</option>
                        <option value="3">50 - 60</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Category
                    </label>
                    <input type="text" class="form-control shadow-none" value="Software Engineer">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Allow in search listing
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>Yes</option>
                        <option value="1">No</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label>
                        About company
                    </label>
                    <textarea class="form-control shadow-none" cols="30" rows="8">Welcome to a world of endless possibilities at Jove! It's not just a job search; it's a career evolution. Uncover your potential, chase your ambitions, and step into a realm where your dream job is not just a fantasy – it's waiting for you.</textarea>
                </div>
            </div>
        </div>
        <button type="submit" class="default-btn">
            Save
        </button>
    </form>
</div>
<div class="jove-card">
    <h3 class="title">
        Social Network
    </h3>
    <form>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Facebook
                    </label>
                    <input type="text" class="form-control shadow-none" value="https://www.facebook.com/">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Twitter
                    </label>
                    <input type="text" class="form-control shadow-none" value="https://twitter.com/">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Linkedin
                    </label>
                    <input type="text" class="form-control shadow-none" value="https://www.linkedin.com/">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Instagram
                    </label>
                    <input type="text" class="form-control shadow-none" value="https://www.instagram.com/">
                </div>
            </div>
        </div>
        <button type="submit" class="default-btn">
            Save
        </button>
    </form>
</div>
<div class="jove-card">
    <h3 class="title">
        Contact Information
    </h3>
    <form>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Country
                    </label>
                    <select class="form-select shadow-none">
                        <option value="1" selected>Japan</option>
                        <option value="2">USA</option>
                        <option value="3">England</option>
                        <option value="4">China</option>
                        <option value="5">Canada</option>
                        <option value="6">Brazil</option>
                        <option value="7">South Korea</option>
                        <option value="8">Spain</option>
                        <option value="9">Turkey</option>
                        <option value="10">Japan</option>
                        <option value="11">Denmark</option>
                        <option value="12">Thailand</option>
                        <option value="13">UAE</option>
                        <option value="14">Australia</option>
                        <option value="15">Netherlands</option>
                        <option value="16">France</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        City
                    </label>
                    <select class="form-select shadow-none">
                        <option value="1" selected>Tokyo</option>
                        <option value="2">New York</option>
                        <option value="3">London</option>
                        <option value="4">Beijing</option>
                        <option value="5">Toronto</option>
                        <option value="6">Chicago</option>
                        <option value="7">Hong Kong</option>
                        <option value="8">Los Angeles</option>
                        <option value="9">São Paulo</option>
                        <option value="10">Seoul</option>
                        <option value="11">Barcelona</option>
                        <option value="12">Istanbul</option>
                        <option value="13">Osaka</option>
                        <option value="14">Copenhagen</option>
                        <option value="15">Madrid</option>
                        <option value="16">Bangkok</option>
                        <option value="17">Dubai</option>
                        <option value="18">Melbourne</option>
                        <option value="19">Amsterdam</option>
                        <option value="20">Paris</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label>
                        Complete address
                    </label>
                    <input type="text" class="form-control shadow-none" value="84 Wexham Road RH19 8EA, USA">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Latitude
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Longitude
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
        </div>
        <button type="submit" class="default-btn">
            Save
        </button>
    </form>
</div>