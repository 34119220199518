<div class="why-choose-us-area position-relative z-1 ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="why-choose-us-content" data-aos="fade-in" data-aos-duration="500">
                    <h2>
                        Join “Jove” today and elevate your team!
                    </h2>
                    <p>
                        At Jove, we've streamlined the search for top-tier talent, making it easier than ever to find the perfect candidates for your team. Say goodbye to sifting through countless resumes and spending hours on various platforms – we bring the talent directly to you.
                    </p>
                    <h4 class="fw-semibold">
                        Why Choose Jove?
                    </h4>
                    <ul class="features-list ps-0 list-unstyled">
                        <li class="position-relative fw-medium">
                            <i class="ri-check-line"></i>
                            Comprehensive talent pool
                        </li>
                        <li class="position-relative fw-medium">
                            <i class="ri-check-line"></i>
                            Advanced filters
                        </li>
                        <li class="position-relative fw-medium">
                            <i class="ri-check-line"></i>
                            Efficient communication
                        </li>
                        <li class="position-relative fw-medium">
                            <i class="ri-check-line"></i>
                            Time-saving convenience
                        </li>
                    </ul>
                    <a routerLink="/about" class="default-btn two">
                        Learn More
                    </a>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="why-choose-us-image text-center" data-aos="fade-up" data-aos-duration="500">
                    <img src="assets/images/why-choose-us.png" alt="why-choose-us-image">
                </div>
            </div>
        </div>
    </div>
    <img src="assets/images/man.png" class="shape" alt="man-image" data-aos="fade-up" data-aos-duration="500">
</div>