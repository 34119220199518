<div
    class="companies-area pt-100 pb-75 bg-eaf0f3"
    [ngClass]="{'style-two': router.url === '/index-2'}"
>
    <div class="container">
        <div class="section-title text-center mx-auto">
            <h2 class="mb-0">
                Get the posts of the world's top companies in one place
            </h2>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="col" data-aos="fade-in" data-aos-duration="500">
                <div class="single-company text-center">
                    <a routerLink="/employer-details" class="d-inline-block">
                        <img src="assets/images/companies/company1.png" alt="company-image">
                    </a>
                </div>
            </div>
            <div class="col" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-company text-center">
                    <a routerLink="/employer-details" class="d-inline-block">
                        <img src="assets/images/companies/company2.png" alt="company-image">
                    </a>
                </div>
            </div>
            <div class="col" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="single-company text-center">
                    <a routerLink="/employer-details" class="d-inline-block">
                        <img src="assets/images/companies/company3.png" alt="company-image">
                    </a>
                </div>
            </div>
            <div class="col" data-aos="fade-in" data-aos-duration="500" data-aos-delay="300">
                <div class="single-company text-center">
                    <a routerLink="/employer-details" class="d-inline-block">
                        <img src="assets/images/companies/company4.png" alt="company-image">
                    </a>
                </div>
            </div>
            <div class="col" data-aos="fade-in" data-aos-duration="500" data-aos-delay="400">
                <div class="single-company text-center">
                    <a routerLink="/employer-details" class="d-inline-block">
                        <img src="assets/images/companies/company5.png" alt="company-image">
                    </a>
                </div>
            </div>
            <div class="col" data-aos="fade-in" data-aos-duration="500" data-aos-delay="500">
                <div class="single-company text-center">
                    <a routerLink="/employer-details" class="d-inline-block">
                        <img src="assets/images/companies/company6.png" alt="company-image">
                    </a>
                </div>
            </div>
            <div class="col" data-aos="fade-in" data-aos-duration="500" data-aos-delay="600">
                <div class="single-company text-center">
                    <a routerLink="/employer-details" class="d-inline-block">
                        <img src="assets/images/companies/company7.png" alt="company-image">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>