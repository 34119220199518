/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminGalery } from '../models/admin-galery';

@Injectable({
  providedIn: 'root',
})
export class AdminGaleryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getadminGaleriesList
   */
  static readonly GetadminGaleriesListPath = '/admin/galeries';

  /**
   * Get list of galeries.
   *
   * Returns list of galeries
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminGaleriesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminGaleriesList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminGalery>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminGaleryService.GetadminGaleriesListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminGalery>;
        }>;
      })
    );
  }

  /**
   * Get list of galeries.
   *
   * Returns list of galeries
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminGaleriesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminGaleriesList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminGalery>;
}> {

    return this.getadminGaleriesList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminGalery>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminGalery>;
})
    );
  }

  /**
   * Path part for operation storeadminGalery
   */
  static readonly StoreadminGaleryPath = '/admin/galeries';

  /**
   * Store new Galery.
   *
   * Returns Galery data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeadminGalery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminGalery$Response(params: {
    body: AdminGalery
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGalery;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminGaleryService.StoreadminGaleryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminGalery;
        }>;
      })
    );
  }

  /**
   * Store new Galery.
   *
   * Returns Galery data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeadminGalery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminGalery(params: {
    body: AdminGalery
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGalery;
}> {

    return this.storeadminGalery$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGalery;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGalery;
})
    );
  }

  /**
   * Path part for operation getadminGaleryId
   */
  static readonly GetadminGaleryIdPath = '/admin/galeries/{id}';

  /**
   * Get  Galery by id.
   *
   * Returns Galery data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminGaleryId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminGaleryId$Response(params: {

    /**
     * id of Galery
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGalery;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminGaleryService.GetadminGaleryIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminGalery;
        }>;
      })
    );
  }

  /**
   * Get  Galery by id.
   *
   * Returns Galery data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminGaleryId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminGaleryId(params: {

    /**
     * id of Galery
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGalery;
}> {

    return this.getadminGaleryId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGalery;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGalery;
})
    );
  }

  /**
   * Path part for operation putadminGalery
   */
  static readonly PutadminGaleryPath = '/admin/galeries/{id}';

  /**
   * Update Galery.
   *
   * Returns Galery data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putadminGalery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminGalery$Response(params: {

    /**
     * id of Galery
     */
    id: string;
    body: AdminGalery
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGalery;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminGaleryService.PutadminGaleryPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminGalery;
        }>;
      })
    );
  }

  /**
   * Update Galery.
   *
   * Returns Galery data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putadminGalery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminGalery(params: {

    /**
     * id of Galery
     */
    id: string;
    body: AdminGalery
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGalery;
}> {

    return this.putadminGalery$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGalery;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminGalery;
})
    );
  }

  /**
   * Path part for operation deleteadminGalery
   */
  static readonly DeleteadminGaleryPath = '/admin/galeries/{id}';

  /**
   * Store new Galery.
   *
   * Returns Galery data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteadminGalery()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminGalery$Response(params: {

    /**
     * id of Galery
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminGaleryService.DeleteadminGaleryPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Galery.
   *
   * Returns Galery data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteadminGalery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminGalery(params: {

    /**
     * id of Galery
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteadminGalery$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
