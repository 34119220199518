<div
    class="banner-area"
    style="background-image: url(assets/images/banner/banner-bg4.jpg);"
>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-xl-7 col-md-12" data-aos="fade-up" data-aos-duration="500">
                <div class="banner-content position-relative">
                    <h1>
                        Enter the new world through jobs
                    </h1>
                    <div class="info d-flex align-items-center justify-content-between">
                        <span class="fw-medium">Find the right job</span>
                        <span class="fw-medium">Looking to hire talent?</span>
                    </div>
                    <form class="banner-form">
                        <div class="row mx-0 align-items-center">
                            <div class="col-4 px-0">
                                <div class="form-group position-relative">
                                    <i class="ri-search-line"></i>
                                    <input type="text" class="form-control" placeholder="Job title, key words or company">
                                </div>
                            </div>
                            <div class="col-4 px-0">
                                <div class="form-group position-relative">
                                    <i class="ri-map-pin-line"></i>
                                    <select class="form-select">
                                        <option selected>All Location</option>
                                        <option value="1">Tokyo, Japan</option>
                                        <option value="2">New York, USA</option>
                                        <option value="3">London, England</option>
                                        <option value="4">Beijing, China</option>
                                        <option value="5">Toronto, Canada</option>
                                        <option value="6">Chicago, USA</option>
                                        <option value="7">Hong Kong, China</option>
                                        <option value="8">Los Angeles, USA</option>
                                        <option value="9">São Paulo, Brazil</option>
                                        <option value="10">Seoul, South Korea</option>
                                        <option value="11">Barcelona, Spain</option>
                                        <option value="12">Istanbul, Turkey</option>
                                        <option value="13">Osaka, Japan</option>
                                        <option value="14">Copenhagen, Denmark</option>
                                        <option value="15">Madrid, Spain</option>
                                        <option value="16">Bangkok, Thailand</option>
                                        <option value="17">Dubai, UAE</option>
                                        <option value="18">Melbourne, Australia</option>
                                        <option value="19">Amsterdam, Netherlands</option>
                                        <option value="20">Paris, France</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-4 px-0">
                                <button type="submit" class="default-btn">
                                    Search Jobs
                                </button>
                            </div>
                        </div>
                    </form>
                    <ul class="features-list ps-0 mb-0 list-unstyled d-flex align-items-center justify-content-between">
                        <li>
                            Live jobs: <span class="fw-semibold">58,612</span>
                        </li>
                        <li>
                            Companies: <span class="fw-semibold">1,042</span>
                        </li>
                        <li>
                            New jobs: <span class="fw-semibold">169</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-5 col-md-12" data-aos="fade-up" data-aos-duration="500">
                <div class="banner-image text-center">
                    <img src="assets/images/banner/banner2.jpg" alt="banner-image">
                </div>
            </div>
        </div>
    </div>
</div>