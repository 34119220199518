<app-navbar></app-navbar>

<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <h1>
                    Job Details
                </h1>
            </div>
            <div class="col-lg-4 col-md-12">
                <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-inline-block position-relative">
                        <a routerLink="/">
                            Home
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        <a routerLink="/jobs-grid">
                            Jobs
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        Job Details
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Job Details Area -->
<div class="job-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="single-job-box">
                    <div class="d-sm-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company8.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    Mechanical Engineer
                                </h3>
                                <span class="location d-block">
                                    Topoint in London
                                </span>
                                <span class="job-type d-block">
                                    Full Time
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn">
                            <a href="javascript:void(0);" class="default-btn">
                                Apply
                            </a>
                        </div>
                    </div>
                </div>
                <div class="job-details-description">
                    <h3>
                        Job description
                    </h3>
                    <p>
                        Enim urna pulvinar orci leo viverra. A augue nisl convallis amet pellentesque sollicitudin. Commodo volutpat euismod nec duis iaculis mattis quam diam. Sollicitudin eu mi aliquet at tellus sed pellentesque. Id non in pharetra massa urna ullamrper tempor. Leo tempus pharetra mi mattis ut porttitor viverra. Suspendisse massa eros pulvinar eu tortor semper sit. In tempusss euismod id scelerisque convallis praesent. Viverra tortor sit volutpat elementum nibh leo rhoncus viverra vestibulum. At non nunc fames vulputate nibh pharetra eu nulla aenean. Fusce pretium purus pellentesque.
                    </p>
                    <h3>
                        Job responsibilities
                    </h3>
                    <p>
                        Enim urna pulvinar orci leo viverra. A augue nisl convallis amet pellentesque sollicitudin. Commodo volutpat euismod nec duis iaculis mattis quam diam. Sollicitudin eu mi aliquet at tellus sed pellentesque. Id non in pharetra massa urna.
                    </p>
                    <ul>
                        <li>
                            Produce outline designs
                        </li>
                        <li>
                            Test and evaluate theoretical designs
                        </li>
                        <li>
                            Estimate budget and scope of project
                        </li>
                        <li>
                            Solicit observations from operators
                        </li>
                        <li>
                            Prepare product reports and documentation
                        </li>
                        <li>
                            Engage in lifelong learning and develop new theories or methods
                        </li>
                    </ul>
                    <h3>
                        Job requirements
                    </h3>
                    <ul>
                        <li>
                            Proven working experience in mechanical engineering
                        </li>
                        <li>
                            Hands-on experience with computer-aided engineering (CAM) and computer-aided manufacturing (CAE)
                        </li>
                        <li>
                            Familiarity with 2D or 3D engineering design and manufacturing tools (e.g., AutoCAD, ProE or other)
                        </li>
                        <li>
                            Adequate knowledge of engineering analysis tools (ANSYS, ProMechanica or similar)
                        </li>
                        <li>
                            Mathematical computing and analysis tools knowledge (Matlab, Excel, LabView etc)
                        </li>
                    </ul>
                    <h3>
                        Compensation & other benefits
                    </h3>
                    <ul>
                        <li>
                            A career in mechanical engineering allows you to build a better future for you, and for the world.
                        </li>
                        <li>
                            This explains why 89% of engineers have high levels of job satisfaction, and would choose the same career path again.
                        </li>
                        <li>
                            85% of engineering graduates go on to further study.
                        </li>
                        <li>
                            Or you could create an invention that changes the world.
                        </li>
                    </ul>
                    <h3>
                        Additional requirements
                    </h3>
                    <p>
                        Enim urna pulvinar orci leo viverra. A augue nisl convallis amet pellentesque sollicitudin. Commodo volutpat euismod nec duis iaculis mattis quam diam. Sollicitudin eu mi aliquet at tellus sed pellentesque. Id non in pharetra massa urna.
                    </p>
                    <ul>
                        <li>
                            Leo tempus pharetra mi mattis ut porttitor viverra.
                        </li>
                        <li>
                            Suspendisse massa eros pulvinar eu tortor semper sit.
                        </li>
                        <li>
                            At non nunc fames vulputate nibh pharetra eu nulla aenean.
                        </li>
                        <li>
                            Fusce pretium purus pellentesque faucibus sed neque diam a maecenas.
                        </li>
                    </ul>
                    <h3>
                        How to apply
                    </h3>
                    <p>
                        Interested candidates can submit their resume and cover letter to <a href="mailto:hello@jove.com">hello&#64;jove.com</a> with the subject line "Mechanical Engineer". Please include your portfolio or GitHub repository, if applicable.
                    </p>
                </div>
                <div class="related-jobs">
                    <h3>
                        Related jobs
                    </h3>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="job-item text-center overflow-hidden position-relative">
                                <div class="content">
                                    <div class="company-logo">
                                        <img src="assets/images/companies/company8.png" alt="company-image">
                                    </div>
                                    <h3 class="fw-semibold">
                                        <a routerLink="/">
                                            Mechanical Engineer
                                        </a>
                                    </h3>
                                    <span class="location d-block">
                                        Topoint in London
                                    </span>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <span class="job-type d-block">
                                            Full Time
                                        </span>
                                        <span class="deadline d-block">
                                            <span class="fw-medium">Deadline:</span>
                                            Oct 30, 2023
                                        </span>
                                    </div>
                                </div>
                                <div class="info d-flex align-items-center justify-content-between">
                                    <span class="salary d-block">
                                        <span class="fw-semibold">Salary:</span>
                                        <span>$1K - $3K</span>
                                        /monthly
                                    </span>
                                    <span class="deadline d-block">
                                        <a routerLink="/" class="link-btn d-inline-block position-relative">
                                            Apply Now <i class="ri-arrow-right-line"></i>
                                        </a>
                                    </span>
                                </div>
                                <button type="button" class="fav">
                                    <i class="ri-heart-line"></i>
                                </button>
                                <span class="tag">Featured</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="job-item text-center overflow-hidden position-relative">
                                <div class="content">
                                    <div class="company-logo">
                                        <img src="assets/images/companies/company9.png" alt="company-image">
                                    </div>
                                    <h3 class="fw-semibold">
                                        <a routerLink="/">
                                            Senior Support Engineer
                                        </a>
                                    </h3>
                                    <span class="location d-block">
                                        Zayper in Barcelona
                                    </span>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <span class="job-type d-block">
                                            Part Time
                                        </span>
                                        <span class="deadline d-block">
                                            <span class="fw-medium">Deadline:</span>
                                            Nov 10, 2023
                                        </span>
                                    </div>
                                </div>
                                <div class="info d-flex align-items-center justify-content-between">
                                    <span class="salary d-block">
                                        <span class="fw-semibold">Salary:</span>
                                        <span>$10 - $50</span>
                                        /hourly
                                    </span>
                                    <span class="deadline d-block">
                                        <a routerLink="/" class="link-btn d-inline-block position-relative">
                                            Apply Now <i class="ri-arrow-right-line"></i>
                                        </a>
                                    </span>
                                </div>
                                <button type="button" class="fav">
                                    <i class="ri-heart-line"></i>
                                </button>
                                <span class="tag urgent">Urgent</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="sidebar-area">
                    <div class="box">
                        <h3>
                            Job summary
                        </h3>
                        <ul class="job-summary ps-0 mb-0 list-unstyled">
                            <li>
                                <span class="fw-medium">Published on:</span> 29 Oct 2023
                            </li>
                            <li>
                                <span class="fw-medium">Vacancy:</span> 01
                            </li>
                            <li>
                                <span class="fw-medium">Job type:</span> Full time
                            </li>
                            <li>
                                <span class="fw-medium">Experience:</span> At least 5 Year(s)
                            </li>
                            <li>
                                <span class="fw-medium">Job location:</span> London
                            </li>
                            <li>
                                <span class="fw-medium">Category:</span> Engineer
                            </li>
                            <li>
                                <span class="fw-medium">Gender:</span> Both
                            </li>
                            <li>
                                <span class="fw-medium">Salary:</span> $1K - $3K
                            </li>
                            <li>
                                <span class="fw-medium">Application deadline:</span> 15 Nov 2023
                            </li>
                        </ul>
                    </div>
                    <div class="box">
                        <h3>
                            Job location
                        </h3>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2504.0527637848595!2d-0.012178123402697727!3d51.12593477172904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875f6256d342681%3A0xb7244723d598f71b!2sRoyal%20Mail!5e0!3m2!1sen!2sbd!4v1696155184932!5m2!1sen!2sbd"></iframe>
                    </div>
                    <div class="box">
                        <h3>
                            Share this job
                        </h3>
                        <ul class="socials-link mb-0 ps-0 list-unstyled">
                            <li>
                                <a href="#" target="_blank" class="facebook d-flex align-items-center">
                                    <span class="d-block">
                                        <i class="ri-facebook-fill"></i>
                                    </span>
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="twitter d-flex align-items-center">
                                    <span class="d-block">
                                        <i class="ri-twitter-x-line"></i>
                                    </span>
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="linkedin d-flex align-items-center">
                                    <span class="d-block">
                                        <i class="ri-linkedin-fill"></i>
                                    </span>
                                    Linkedin
                                </a>
                            </li>
                        </ul>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>

<app-subscribe></app-subscribe>

<app-footer></app-footer>

<ngx-scrolltop></ngx-scrolltop>