/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CmsSlide } from '../models/cms-slide';

@Injectable({
  providedIn: 'root',
})
export class CmsSlideService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getcmsSlidesList
   */
  static readonly GetcmsSlidesListPath = '/cms/slides';

  /**
   * Get list of slides.
   *
   * Returns list of slides
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getcmsSlidesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getcmsSlidesList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsSlide>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsSlideService.GetcmsSlidesListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<CmsSlide>;
        }>;
      })
    );
  }

  /**
   * Get list of slides.
   *
   * Returns list of slides
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getcmsSlidesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getcmsSlidesList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsSlide>;
}> {

    return this.getcmsSlidesList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsSlide>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsSlide>;
})
    );
  }

  /**
   * Path part for operation storecmsSlide
   */
  static readonly StorecmsSlidePath = '/cms/slides';

  /**
   * Store new Slide.
   *
   * Returns Slide data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storecmsSlide()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storecmsSlide$Response(params: {
    body: CmsSlide
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsSlide;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsSlideService.StorecmsSlidePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: CmsSlide;
        }>;
      })
    );
  }

  /**
   * Store new Slide.
   *
   * Returns Slide data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storecmsSlide$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storecmsSlide(params: {
    body: CmsSlide
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsSlide;
}> {

    return this.storecmsSlide$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsSlide;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsSlide;
})
    );
  }

  /**
   * Path part for operation getcmsSlideId
   */
  static readonly GetcmsSlideIdPath = '/cms/slides/{id}';

  /**
   * Get  Slide by id.
   *
   * Returns Slide data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getcmsSlideId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getcmsSlideId$Response(params: {

    /**
     * id of Slide
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsSlide;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsSlideService.GetcmsSlideIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: CmsSlide;
        }>;
      })
    );
  }

  /**
   * Get  Slide by id.
   *
   * Returns Slide data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getcmsSlideId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getcmsSlideId(params: {

    /**
     * id of Slide
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsSlide;
}> {

    return this.getcmsSlideId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsSlide;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsSlide;
})
    );
  }

  /**
   * Path part for operation putcmsSlide
   */
  static readonly PutcmsSlidePath = '/cms/slides/{id}';

  /**
   * Update Slide.
   *
   * Returns Slide data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putcmsSlide()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putcmsSlide$Response(params: {

    /**
     * id of Slide
     */
    id: string;
    body: CmsSlide
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsSlide;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsSlideService.PutcmsSlidePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: CmsSlide;
        }>;
      })
    );
  }

  /**
   * Update Slide.
   *
   * Returns Slide data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putcmsSlide$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putcmsSlide(params: {

    /**
     * id of Slide
     */
    id: string;
    body: CmsSlide
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsSlide;
}> {

    return this.putcmsSlide$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsSlide;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsSlide;
})
    );
  }

  /**
   * Path part for operation deletecmsSlide
   */
  static readonly DeletecmsSlidePath = '/cms/slides/{id}';

  /**
   * Store new Slide.
   *
   * Returns Slide data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletecmsSlide()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletecmsSlide$Response(params: {

    /**
     * id of Slide
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsSlideService.DeletecmsSlidePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Slide.
   *
   * Returns Slide data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletecmsSlide$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletecmsSlide(params: {

    /**
     * id of Slide
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deletecmsSlide$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
