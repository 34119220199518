import { Component } from '@angular/core';

@Component({
  selector: 'app-ed-resumes',
  templateUrl: './ed-resumes.component.html',
  styleUrls: ['./ed-resumes.component.scss']
})
export class EdResumesComponent {

}
