<app-navbar></app-navbar>

<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <h1>
                    Contact Us
                </h1>
            </div>
            <div class="col-lg-4 col-md-12">
                <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-inline-block position-relative">
                        <a routerLink="/">
                            Home
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        Contact Us
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Contact Us -->
<div class="contact-area ptb-100">
    <div class="container">
        <div class="section-title text-start">
            <h2 class="mb-0">
                Find your answer? Ask here
            </h2>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="contact-form">
                    <form>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>
                                        Name
                                    </label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>
                                        Email
                                    </label>
                                    <input type="email" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>
                                        Phone
                                    </label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>
                                        Subject
                                    </label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>
                                        Your message
                                    </label>
                                    <textarea class="form-control" cols="30" rows="5"></textarea>
                                </div>
                            </div>
                        </div>
                        <button class="default-btn" type="submit">
                            Send Message
                        </button>
                    </form>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="contact-info">
                    <h3>
                        Contact information
                    </h3>
                    <ul class="info ps-0 list-unstyled">
                        <li class="position-relative">
                            <i class="ri-map-pin-line"></i>
                            <span class="d-block">Address:</span>
                            <a href="#" target="_blank">
                                84 Wexham Road RH19 8EA, USA
                            </a>
                        </li>
                        <li class="position-relative">
                            <i class="ri-mail-line"></i>
                            <span class="d-block">Email:</span>
                            <a href="mailto:hello@jove.com">
                                hello&#64;jove.com
                            </a>
                        </li>
                        <li class="position-relative">
                            <i class="ri-phone-line"></i>
                            <span class="d-block">Phone:</span>
                            <a href="mailto:+1 (514) 312-5678">
                                +1 (514) 312-5678
                            </a>
                        </li>
                    </ul>
                    <h3>
                        Social Profiles
                    </h3>
                    <ul class="socials-link mb-0 ps-0 list-unstyled">
                        <li>
                            <a href="#" target="_blank" class="facebook d-flex align-items-center">
                                <span class="d-block">
                                    <i class="ri-facebook-fill"></i>
                                </span>
                                Facebook
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="twitter d-flex align-items-center">
                                <span class="d-block">
                                    <i class="ri-twitter-x-line"></i>
                                </span>
                                Twitter
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="linkedin d-flex align-items-center">
                                <span class="d-block">
                                    <i class="ri-linkedin-fill"></i>
                                </span>
                                Linkedin
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Maps -->
<div class="overflow-hidden">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2504.0527637848595!2d-0.012178123402697727!3d51.12593477172904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875f6256d342681%3A0xb7244723d598f71b!2sRoyal%20Mail!5e0!3m2!1sen!2sbd!4v1696233676982!5m2!1sen!2sbd" style="border:0; display: block; height: 600px; width: 100%;"></iframe>
</div>

<app-subscribe></app-subscribe>

<app-footer></app-footer>

<ngx-scrolltop></ngx-scrolltop>