/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientMasterVarietes } from '../models/client-master-varietes';

@Injectable({
  providedIn: 'root',
})
export class ClientMasterVarietesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientgetmasterVarietesList
   */
  static readonly ClientgetmasterVarietesListPath = '/client/masterVarietes';

  /**
   * Get list of masterVarietes.
   *
   * Returns list of masterVarietes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientgetmasterVarietesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetmasterVarietesList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterVarietes>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterVarietesService.ClientgetmasterVarietesListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientMasterVarietes>;
        }>;
      })
    );
  }

  /**
   * Get list of masterVarietes.
   *
   * Returns list of masterVarietes
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientgetmasterVarietesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetmasterVarietesList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterVarietes>;
}> {

    return this.clientgetmasterVarietesList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterVarietes>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterVarietes>;
})
    );
  }

  /**
   * Path part for operation clientgetMasterVarietesId
   */
  static readonly ClientgetMasterVarietesIdPath = '/client/masterVarietes/{id}';

  /**
   * Get  Master_varietes by id.
   *
   * Returns Master_varietes data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientgetMasterVarietesId()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetMasterVarietesId$Response(params: {

    /**
     * id of Master_varietes
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterVarietes;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterVarietesService.ClientgetMasterVarietesIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientMasterVarietes;
        }>;
      })
    );
  }

  /**
   * Get  Master_varietes by id.
   *
   * Returns Master_varietes data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientgetMasterVarietesId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetMasterVarietesId(params: {

    /**
     * id of Master_varietes
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterVarietes;
}> {

    return this.clientgetMasterVarietesId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterVarietes;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterVarietes;
})
    );
  }

}
