<div class="jobs-by-location-area overflow-hidden ptb-100">
    <div class="container">
        <div class="section-title text-start">
            <h2 class="mb-0">
                Jobs by location
            </h2>
        </div>
    </div>
    <div class="container-fluid jobsByLocationSlides" data-aos="fade-up" data-aos-duration="500">
        <owl-carousel-o [options]="jobsByLocationSlides">
            <ng-template carouselSlide>
                <div class="job-item">
                    <a routerLink="/jobs-grid" class="d-block image">
                        <img src="assets/images/locations/location1.jpg" alt="location-image">
                    </a>
                    <h3 class="fw-semibold">
                        <a routerLink="/jobs-grid">
                            United States
                        </a>
                    </h3>
                    <span class="d-block">
                        Open jobs
                        <span>(120)</span>
                    </span>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="job-item">
                    <a routerLink="/jobs-grid" class="d-block image">
                        <img src="assets/images/locations/location2.jpg" alt="location-image">
                    </a>
                    <h3 class="fw-semibold">
                        <a routerLink="/jobs-grid">
                            United Kingdom
                        </a>
                    </h3>
                    <span class="d-block">
                        Open jobs
                        <span>(99)</span>
                    </span>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="job-item">
                    <a routerLink="/jobs-grid" class="d-block image">
                        <img src="assets/images/locations/location3.jpg" alt="location-image">
                    </a>
                    <h3 class="fw-semibold">
                        <a routerLink="/jobs-grid">
                            Canada
                        </a>
                    </h3>
                    <span class="d-block">
                        Open jobs
                        <span>(176)</span>
                    </span>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="job-item">
                    <a routerLink="/jobs-grid" class="d-block image">
                        <img src="assets/images/locations/location4.jpg" alt="location-image">
                    </a>
                    <h3 class="fw-semibold">
                        <a routerLink="/jobs-grid">
                            France
                        </a>
                    </h3>
                    <span class="d-block">
                        Open jobs
                        <span>(322)</span>
                    </span>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="job-item">
                    <a routerLink="/jobs-grid" class="d-block image">
                        <img src="assets/images/locations/location5.jpg" alt="location-image">
                    </a>
                    <h3 class="fw-semibold">
                        <a routerLink="/jobs-grid">
                            United Arab Emirates
                        </a>
                    </h3>
                    <span class="d-block">
                        Open jobs
                        <span>(142)</span>
                    </span>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="job-item">
                    <a routerLink="/jobs-grid" class="d-block image">
                        <img src="assets/images/locations/location3.jpg" alt="location-image">
                    </a>
                    <h3 class="fw-semibold">
                        <a routerLink="/jobs-grid">
                            Canada
                        </a>
                    </h3>
                    <span class="d-block">
                        Open jobs
                        <span>(176)</span>
                    </span>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>