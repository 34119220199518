import { Component } from '@angular/core';

@Component({
  selector: 'app-cd-alert-jobs',
  templateUrl: './cd-alert-jobs.component.html',
  styleUrls: ['./cd-alert-jobs.component.scss']
})
export class CdAlertJobsComponent {

}
