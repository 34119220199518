/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientMasterHabitus } from '../models/client-master-habitus';

@Injectable({
  providedIn: 'root',
})
export class ClientMasterHabitusService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientMasterHabitusesList
   */
  static readonly GetclientMasterHabitusesListPath = '/client/masterHabituses';

  /**
   * Get list of masterHabituses.
   *
   * Returns list of masterHabituses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientMasterHabitusesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientMasterHabitusesList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterHabitus>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterHabitusService.GetclientMasterHabitusesListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientMasterHabitus>;
        }>;
      })
    );
  }

  /**
   * Get list of masterHabituses.
   *
   * Returns list of masterHabituses
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientMasterHabitusesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientMasterHabitusesList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterHabitus>;
}> {

    return this.getclientMasterHabitusesList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterHabitus>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterHabitus>;
})
    );
  }

  /**
   * Path part for operation storeclientMasterHabitus
   */
  static readonly StoreclientMasterHabitusPath = '/client/masterHabituses';

  /**
   * Store new Master_habitus.
   *
   * Returns Master_habitus data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeclientMasterHabitus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientMasterHabitus$Response(params: {
    body: ClientMasterHabitus
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterHabitus;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterHabitusService.StoreclientMasterHabitusPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientMasterHabitus;
        }>;
      })
    );
  }

  /**
   * Store new Master_habitus.
   *
   * Returns Master_habitus data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeclientMasterHabitus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientMasterHabitus(params: {
    body: ClientMasterHabitus
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterHabitus;
}> {

    return this.storeclientMasterHabitus$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterHabitus;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterHabitus;
})
    );
  }

  /**
   * Path part for operation getclientMasterHabitusId
   */
  static readonly GetclientMasterHabitusIdPath = '/client/masterHabituses/{id}';

  /**
   * Get  Master_habitus by id.
   *
   * Returns Master_habitus data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientMasterHabitusId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientMasterHabitusId$Response(params: {

    /**
     * id of Master_habitus
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterHabitus;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterHabitusService.GetclientMasterHabitusIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientMasterHabitus;
        }>;
      })
    );
  }

  /**
   * Get  Master_habitus by id.
   *
   * Returns Master_habitus data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientMasterHabitusId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientMasterHabitusId(params: {

    /**
     * id of Master_habitus
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterHabitus;
}> {

    return this.getclientMasterHabitusId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterHabitus;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterHabitus;
})
    );
  }

  /**
   * Path part for operation putclientMasterHabitus
   */
  static readonly PutclientMasterHabitusPath = '/client/masterHabituses/{id}';

  /**
   * Update Master_habitus.
   *
   * Returns Master_habitus data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putclientMasterHabitus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientMasterHabitus$Response(params: {

    /**
     * id of Master_habitus
     */
    id: string;
    body: ClientMasterHabitus
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterHabitus;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterHabitusService.PutclientMasterHabitusPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientMasterHabitus;
        }>;
      })
    );
  }

  /**
   * Update Master_habitus.
   *
   * Returns Master_habitus data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putclientMasterHabitus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientMasterHabitus(params: {

    /**
     * id of Master_habitus
     */
    id: string;
    body: ClientMasterHabitus
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterHabitus;
}> {

    return this.putclientMasterHabitus$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterHabitus;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterHabitus;
})
    );
  }

  /**
   * Path part for operation deleteclientMasterHabitus
   */
  static readonly DeleteclientMasterHabitusPath = '/client/masterHabituses/{id}';

  /**
   * Store new Master_habitus.
   *
   * Returns Master_habitus data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteclientMasterHabitus()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientMasterHabitus$Response(params: {

    /**
     * id of Master_habitus
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterHabitusService.DeleteclientMasterHabitusPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Master_habitus.
   *
   * Returns Master_habitus data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteclientMasterHabitus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientMasterHabitus(params: {

    /**
     * id of Master_habitus
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteclientMasterHabitus$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
