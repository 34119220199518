<app-navbar></app-navbar>

<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <h1>
                    Candidates Listing
                </h1>
            </div>
            <div class="col-lg-4 col-md-12">
                <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-inline-block position-relative">
                        <a routerLink="/">
                            Home
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        Candidates
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Candidates Area -->
<div class="candidates-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="jove-grid-sorting row align-items-center">
                    <div class="col-lg-6 col-md-6 result-count">
                        <p>
                            Showing <span class="count">1 – 10</span> of 784 results
                        </p>
                    </div>
                    <div class="col-lg-6 col-md-6 ordering">
                        <div class="select-box">
                            <label>Sort By:</label>
                            <select class="form-select">
                                <option selected="">Default</option>
                                <option value="1">Popularity</option>
                                <option value="2">Latest</option>
                                <option value="3">Price: low to high</option>
                                <option value="4">Price: high to low</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate1.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            Donald Darnell
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        Software Engineer
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> London
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $50/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate2.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            Olivie Riquier
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        Accountant
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> Chicago
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $54/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate3.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            Edwin Lloyd
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        Data Scientist
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> Dallas
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $99/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate4.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            Bryant Hills
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        UI/UX Designer
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> Houston
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $35/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate5.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            Melissa Rodgers
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        Pharmacist
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> Los Angeles
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $45/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate6.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            Jeanne Richardson
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        Marketing Manager
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> New York
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $25/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate7.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            Bruce Saunders
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        Systems Analyst
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> Philadelphia
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $73/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate8.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            Marilyn Graves
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        Tax Consultant
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> London
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $60/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate9.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            William Killion
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        SEO Specialist
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> London
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $70/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate10.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            Rebekah Prior
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        Content Writer
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> San Antonio
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $45/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate11.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            Iraida Smith
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        Risk Manager
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> San Jose
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $60/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate12.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            Megan Bragg
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        Mechanical Engineer
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> Charlotte
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $40/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate13.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            Matthew Rosato
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        IT Project Manager
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> San Francisco
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $39/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate14.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            Vanessa Vasquez
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        Web Developer
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> Jacksonville
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $19/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-candidate-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/candidates/candidate15.jpg" class="rounded-circle" alt="candidate-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/candidate-details">
                                            Ben Carter
                                        </a>
                                    </h3>
                                    <span class="designation d-block">
                                        Network Administrator
                                    </span>
                                    <span class="location d-block">
                                        <span>Location:</span> Abilene
                                    </span>
                                </div>
                            </div>
                            <div class="view-profile d-sm-flex align-items-center">
                                <span class="d-block">
                                    $49/ hour
                                </span>
                                <a routerLink="/candidate-details" class="default-btn">
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination-area">
                    <a routerLink="/candidates" class="next page-numbers">
                        <i class="ri-arrow-left-line"></i>
                    </a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/candidates" class="page-numbers">2</a>
                    <a routerLink="/candidates" class="page-numbers">3</a>
                    <a routerLink="/candidates" class="next page-numbers">
                        <i class="ri-arrow-right-line"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <app-jobs-sidebar></app-jobs-sidebar>
            </div>
        </div>
    </div>
</div>

<app-subscribe></app-subscribe>

<app-footer></app-footer>

<ngx-scrolltop></ngx-scrolltop>