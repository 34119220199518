<app-navbar></app-navbar>

<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <h1>
                    FAQ
                </h1>
            </div>
            <div class="col-lg-4 col-md-12">
                <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-inline-block position-relative">
                        <a routerLink="/">
                            Home
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        FAQ
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<app-faq></app-faq>

<div class="ptb-100">
    <app-download-app></app-download-app>
</div>

<app-subscribe></app-subscribe>

<app-footer></app-footer>

<ngx-scrolltop></ngx-scrolltop>