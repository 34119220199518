/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientSlideImage } from '../models/client-slide-image';

@Injectable({
  providedIn: 'root',
})
export class ClientSlideImageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientgetslideImagesList
   */
  static readonly ClientgetslideImagesListPath = '/client/slideImages';

  /**
   * Get list of slideImages.
   *
   * Returns list of slideImages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientgetslideImagesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetslideImagesList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientSlideImage>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientSlideImageService.ClientgetslideImagesListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientSlideImage>;
        }>;
      })
    );
  }

  /**
   * Get list of slideImages.
   *
   * Returns list of slideImages
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientgetslideImagesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetslideImagesList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientSlideImage>;
}> {

    return this.clientgetslideImagesList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientSlideImage>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientSlideImage>;
})
    );
  }

  /**
   * Path part for operation clientgetSlideImageId
   */
  static readonly ClientgetSlideImageIdPath = '/client/slideImages/{id}';

  /**
   * Get  Slide_image by id.
   *
   * Returns Slide_image data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientgetSlideImageId()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetSlideImageId$Response(params: {

    /**
     * id of Slide_image
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientSlideImage;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientSlideImageService.ClientgetSlideImageIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientSlideImage;
        }>;
      })
    );
  }

  /**
   * Get  Slide_image by id.
   *
   * Returns Slide_image data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientgetSlideImageId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientgetSlideImageId(params: {

    /**
     * id of Slide_image
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientSlideImage;
}> {

    return this.clientgetSlideImageId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientSlideImage;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientSlideImage;
})
    );
  }

}
