<div class="leading-company-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="leading-company-content" data-aos="fade-in" data-aos-duration="500">
                    <div class="box">
                        <h2>
                            We’re a leading job search company.
                        </h2>
                        <p>
                            Step into a world of possibilities at Jove, where careers are shaped, and dreams are realized. Our jobs board is more than just a platform – it's a dynamic ecosystem that connects job seekers and employers, creating pathways to success for both.
                        </p>
                        <div class="row features-list">
                            <div class="col-sm-6">
                                <span class="d-block position-relative">
                                    <i class="ri-check-line"></i>
                                    Jove job listings
                                </span>
                            </div>
                            <div class="col-sm-6">
                                <span class="d-block position-relative">
                                    <i class="ri-check-line"></i>
                                    Real-time updates
                                </span>
                            </div>
                            <div class="col-sm-6">
                                <span class="d-block position-relative">
                                    <i class="ri-check-line"></i>
                                    Cutting-edge technology
                                </span>
                            </div>
                            <div class="col-sm-6">
                                <span class="d-block position-relative">
                                    <i class="ri-check-line"></i>
                                    Responsive support
                                </span>
                            </div>
                            <div class="col-sm-6">
                                <span class="d-block position-relative">
                                    <i class="ri-check-line"></i>
                                    User-friendly interface
                                </span>
                            </div>
                            <div class="col-sm-6">
                                <span class="d-block position-relative">
                                    <i class="ri-check-line"></i>
                                    Employer satisfaction
                                </span>
                            </div>
                            <div class="col-sm-6">
                                <span class="d-block position-relative">
                                    <i class="ri-check-line"></i>
                                    Direct communication
                                </span>
                            </div>
                            <div class="col-sm-6">
                                <span class="d-block position-relative">
                                    <i class="ri-check-line"></i>
                                    Career advancement
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <h2>
                            Discover your future today!
                        </h2>
                        <p>
                            Your aspirations are the heart of our mission. At Jove, we believe in the power of connections and the potential they hold. Join us today and unlock a realm of opportunities that lead you to the career you've always envisioned.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="leading-company-image text-center" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                    <img src="assets/images/features/feature4.jpg" alt="leading-company-image">
                </div>
            </div>
        </div>
    </div>
</div>