import { Component } from '@angular/core';

@Component({
  selector: 'app-ed-change-password',
  templateUrl: './ed-change-password.component.html',
  styleUrls: ['./ed-change-password.component.scss']
})
export class EdChangePasswordComponent {

}
