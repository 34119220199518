/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CmsPesan } from '../models/cms-pesan';

@Injectable({
  providedIn: 'root',
})
export class CmsPesanService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getpesansList
   */
  static readonly GetpesansListPath = '/cms/pesans';

  /**
   * Get list of pesans.
   *
   * Returns list of pesans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getpesansList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getpesansList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsPesan>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsPesanService.GetpesansListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<CmsPesan>;
        }>;
      })
    );
  }

  /**
   * Get list of pesans.
   *
   * Returns list of pesans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getpesansList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getpesansList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsPesan>;
}> {

    return this.getpesansList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsPesan>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<CmsPesan>;
})
    );
  }

  /**
   * Path part for operation storePesan
   */
  static readonly StorePesanPath = '/cms/pesans';

  /**
   * Store new Pesan.
   *
   * Returns Pesan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storePesan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storePesan$Response(params: {
    body: CmsPesan
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPesan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsPesanService.StorePesanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: CmsPesan;
        }>;
      })
    );
  }

  /**
   * Store new Pesan.
   *
   * Returns Pesan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storePesan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storePesan(params: {
    body: CmsPesan
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPesan;
}> {

    return this.storePesan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPesan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPesan;
})
    );
  }

  /**
   * Path part for operation getPesanId
   */
  static readonly GetPesanIdPath = '/cms/pesans/{id}';

  /**
   * Get  Pesan by id.
   *
   * Returns Pesan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPesanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPesanId$Response(params: {

    /**
     * id of Pesan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPesan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsPesanService.GetPesanIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: CmsPesan;
        }>;
      })
    );
  }

  /**
   * Get  Pesan by id.
   *
   * Returns Pesan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPesanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPesanId(params: {

    /**
     * id of Pesan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPesan;
}> {

    return this.getPesanId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPesan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPesan;
})
    );
  }

  /**
   * Path part for operation putPesan
   */
  static readonly PutPesanPath = '/cms/pesans/{id}';

  /**
   * Update Pesan.
   *
   * Returns Pesan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPesan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPesan$Response(params: {

    /**
     * id of Pesan
     */
    id: string;
    body: CmsPesan
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPesan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsPesanService.PutPesanPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: CmsPesan;
        }>;
      })
    );
  }

  /**
   * Update Pesan.
   *
   * Returns Pesan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPesan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPesan(params: {

    /**
     * id of Pesan
     */
    id: string;
    body: CmsPesan
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPesan;
}> {

    return this.putPesan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPesan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: CmsPesan;
})
    );
  }

  /**
   * Path part for operation deletePesan
   */
  static readonly DeletePesanPath = '/cms/pesans/{id}';

  /**
   * Store new Pesan.
   *
   * Returns Pesan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePesan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePesan$Response(params: {

    /**
     * id of Pesan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CmsPesanService.DeletePesanPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Pesan.
   *
   * Returns Pesan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePesan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePesan(params: {

    /**
     * id of Pesan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deletePesan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
