<!-- Title -->
<div class="breadcrumb-title">
    <h1 class="mb-0 fw-semibold">
        My Resume
    </h1>
</div>

<!-- Resume -->
<div class="jove-card">
    <h3 class="title">
        Personal Details
    </h3>
    <form>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        First name
                    </label>
                    <input type="text" class="form-control shadow-none" value="Alina">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Last name
                    </label>
                    <input type="text" class="form-control shadow-none" value="Smith">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Email address
                    </label>
                    <input type="email" class="form-control shadow-none" value="hello@doca.com">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Phone
                    </label>
                    <input type="text" class="form-control shadow-none" value="+1 (514) 312-5678">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Date of birth
                    </label>
                    <input type="date" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Gender
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>Select</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        <option value="3">Custom</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Marital status
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>Select</option>
                        <option value="1">Married</option>
                        <option value="2">Single</option>
                        <option value="3">Complicated</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Nationality
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>Select</option>
                        <option value="1">Japan</option>
                        <option value="2">USA</option>
                        <option value="3">England</option>
                        <option value="4">China</option>
                        <option value="5">Canada</option>
                        <option value="6">Brazil</option>
                        <option value="7">South Korea</option>
                        <option value="8">Spain</option>
                        <option value="9">Turkey</option>
                        <option value="10">Japan</option>
                        <option value="11">Denmark</option>
                        <option value="12">Thailand</option>
                        <option value="13">UAE</option>
                        <option value="14">Australia</option>
                        <option value="15">Netherlands</option>
                        <option value="16">France</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label>
                        Address details
                    </label>
                    <textarea class="form-control shadow-none" cols="30" rows="8"></textarea>
                </div>
            </div>
        </div>
        <button type="submit" class="default-btn">
            Save
        </button>
    </form>
</div>
<div class="jove-card">
    <h3 class="title">
        Career & Application Information
    </h3>
    <form>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label>
                        Objective
                    </label>
                    <textarea class="form-control shadow-none" cols="30" rows="7"></textarea>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Present salary
                    </label>
                    <input type="text" class="form-control shadow-none" placeholder="$2000">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Expected salary
                    </label>
                    <input type="text" class="form-control shadow-none" placeholder="$3000">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Job level
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>Select</option>
                        <option value="1">High Level</option>
                        <option value="2">Mid Level</option>
                        <option value="3">Low Level</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Job type
                    </label>
                    <select class="form-select shadow-none">
                        <option selected>Select</option>
                        <option value="1">Full Time</option>
                        <option value="2">Part Time</option>
                        <option value="3">Hourly</option>
                    </select>
                </div>
            </div>
        </div>
        <button type="submit" class="default-btn">
            Save
        </button>
    </form>
</div>
<div class="jove-card">
    <h3 class="title">
        Education
    </h3>
    <form>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Level of education
                    </label>
                    <select class="form-select shadow-none">
                        <option value="1" selected>Japan</option>
                        <option value="2">First Class</option>
                        <option value="3">Second Class</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Exam / Degree title
                    </label>
                    <select class="form-select shadow-none">
                        <option value="1" selected>Select</option>
                        <option value="2">B.S.C</option>
                        <option value="3">C.S.C</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Major/Group
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Institute name
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Result
                    </label>
                    <select class="form-select shadow-none">
                        <option value="1" selected>Select</option>
                        <option value="2">CGPA 5</option>
                        <option value="3">CGPA 4</option>
                        <option value="4">CGPA 3</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Marks(%)
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Year of passing
                    </label>
                    <select class="form-select shadow-none">
                        <option value="1" selected>Select</option>
                        <option value="2">2023</option>
                        <option value="3">2022</option>
                        <option value="4">2021</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Duration (years)
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
        </div>
        <button type="submit" class="default-btn">
            Save
        </button>
    </form>
</div>
<div class="jove-card">
    <h3 class="title">
        Experience
    </h3>
    <form>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Company name
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Company business
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Designation
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Department
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Responsibilities
                    </label>
                    <select class="form-select shadow-none">
                        <option value="1" selected>Japan</option>
                        <option value="2">10 Roles and their lists of job responsibilities</option>
                        <option value="3">Should you share job responsibilities in a resume?</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>
                        Company location
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label>
                        Employment period
                    </label>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
        </div>
        <button type="submit" class="default-btn">
            Save
        </button>
    </form>
</div>