<div class="main-content d-flex flex-column">

    <app-cd-header></app-cd-header>

    <app-cd-sidebar></app-cd-sidebar>

    <router-outlet></router-outlet>
    
    <div class="flex-grow-1"></div>

    <app-cd-footer></app-cd-footer>

</div>