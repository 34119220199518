/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminMasterVarietes } from '../models/admin-master-varietes';

@Injectable({
  providedIn: 'root',
})
export class AdminMasterVarietesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getmasterVarietesList
   */
  static readonly GetmasterVarietesListPath = '/admin/masterVarietes';

  /**
   * Get list of masterVarietes.
   *
   * Returns list of masterVarietes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getmasterVarietesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getmasterVarietesList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterVarietes>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterVarietesService.GetmasterVarietesListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminMasterVarietes>;
        }>;
      })
    );
  }

  /**
   * Get list of masterVarietes.
   *
   * Returns list of masterVarietes
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getmasterVarietesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getmasterVarietesList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterVarietes>;
}> {

    return this.getmasterVarietesList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterVarietes>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterVarietes>;
})
    );
  }

  /**
   * Path part for operation storeMasterVarietes
   */
  static readonly StoreMasterVarietesPath = '/admin/masterVarietes';

  /**
   * Store new Master_varietes.
   *
   * Returns Master_varietes data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeMasterVarietes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeMasterVarietes$Response(params: {
    body: AdminMasterVarietes
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterVarietes;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterVarietesService.StoreMasterVarietesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterVarietes;
        }>;
      })
    );
  }

  /**
   * Store new Master_varietes.
   *
   * Returns Master_varietes data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeMasterVarietes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeMasterVarietes(params: {
    body: AdminMasterVarietes
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterVarietes;
}> {

    return this.storeMasterVarietes$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterVarietes;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterVarietes;
})
    );
  }

  /**
   * Path part for operation getMasterVarietesId
   */
  static readonly GetMasterVarietesIdPath = '/admin/masterVarietes/{id}';

  /**
   * Get  Master_varietes by id.
   *
   * Returns Master_varietes data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMasterVarietesId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMasterVarietesId$Response(params: {

    /**
     * id of Master_varietes
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterVarietes;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterVarietesService.GetMasterVarietesIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterVarietes;
        }>;
      })
    );
  }

  /**
   * Get  Master_varietes by id.
   *
   * Returns Master_varietes data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMasterVarietesId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMasterVarietesId(params: {

    /**
     * id of Master_varietes
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterVarietes;
}> {

    return this.getMasterVarietesId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterVarietes;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterVarietes;
})
    );
  }

  /**
   * Path part for operation putMasterVarietes
   */
  static readonly PutMasterVarietesPath = '/admin/masterVarietes/{id}';

  /**
   * Update Master_varietes.
   *
   * Returns Master_varietes data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMasterVarietes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMasterVarietes$Response(params: {

    /**
     * id of Master_varietes
     */
    id: string;
    body: AdminMasterVarietes
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterVarietes;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterVarietesService.PutMasterVarietesPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterVarietes;
        }>;
      })
    );
  }

  /**
   * Update Master_varietes.
   *
   * Returns Master_varietes data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMasterVarietes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMasterVarietes(params: {

    /**
     * id of Master_varietes
     */
    id: string;
    body: AdminMasterVarietes
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterVarietes;
}> {

    return this.putMasterVarietes$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterVarietes;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterVarietes;
})
    );
  }

  /**
   * Path part for operation deleteMasterVarietes
   */
  static readonly DeleteMasterVarietesPath = '/admin/masterVarietes/{id}';

  /**
   * Store new Master_varietes.
   *
   * Returns Master_varietes data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMasterVarietes()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMasterVarietes$Response(params: {

    /**
     * id of Master_varietes
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterVarietesService.DeleteMasterVarietesPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Master_varietes.
   *
   * Returns Master_varietes data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteMasterVarietes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMasterVarietes(params: {

    /**
     * id of Master_varietes
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteMasterVarietes$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
