/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminMasterKeterangan } from '../models/admin-master-keterangan';

@Injectable({
  providedIn: 'root',
})
export class AdminMasterKeteranganService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getmasterKeterangansList
   */
  static readonly GetmasterKeterangansListPath = '/admin/masterKeterangans';

  /**
   * Get list of masterKeterangans.
   *
   * Returns list of masterKeterangans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getmasterKeterangansList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getmasterKeterangansList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterKeterangan>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterKeteranganService.GetmasterKeterangansListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminMasterKeterangan>;
        }>;
      })
    );
  }

  /**
   * Get list of masterKeterangans.
   *
   * Returns list of masterKeterangans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getmasterKeterangansList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getmasterKeterangansList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterKeterangan>;
}> {

    return this.getmasterKeterangansList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterKeterangan>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterKeterangan>;
})
    );
  }

  /**
   * Path part for operation storeMasterKeterangan
   */
  static readonly StoreMasterKeteranganPath = '/admin/masterKeterangans';

  /**
   * Store new Master_keterangan.
   *
   * Returns Master_keterangan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeMasterKeterangan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeMasterKeterangan$Response(params: {
    body: AdminMasterKeterangan
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterKeterangan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterKeteranganService.StoreMasterKeteranganPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterKeterangan;
        }>;
      })
    );
  }

  /**
   * Store new Master_keterangan.
   *
   * Returns Master_keterangan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeMasterKeterangan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeMasterKeterangan(params: {
    body: AdminMasterKeterangan
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterKeterangan;
}> {

    return this.storeMasterKeterangan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterKeterangan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterKeterangan;
})
    );
  }

  /**
   * Path part for operation getMasterKeteranganId
   */
  static readonly GetMasterKeteranganIdPath = '/admin/masterKeterangans/{id}';

  /**
   * Get  Master_keterangan by id.
   *
   * Returns Master_keterangan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMasterKeteranganId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMasterKeteranganId$Response(params: {

    /**
     * id of Master_keterangan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterKeterangan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterKeteranganService.GetMasterKeteranganIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterKeterangan;
        }>;
      })
    );
  }

  /**
   * Get  Master_keterangan by id.
   *
   * Returns Master_keterangan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMasterKeteranganId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMasterKeteranganId(params: {

    /**
     * id of Master_keterangan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterKeterangan;
}> {

    return this.getMasterKeteranganId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterKeterangan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterKeterangan;
})
    );
  }

  /**
   * Path part for operation putMasterKeterangan
   */
  static readonly PutMasterKeteranganPath = '/admin/masterKeterangans/{id}';

  /**
   * Update Master_keterangan.
   *
   * Returns Master_keterangan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMasterKeterangan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMasterKeterangan$Response(params: {

    /**
     * id of Master_keterangan
     */
    id: string;
    body: AdminMasterKeterangan
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterKeterangan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterKeteranganService.PutMasterKeteranganPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterKeterangan;
        }>;
      })
    );
  }

  /**
   * Update Master_keterangan.
   *
   * Returns Master_keterangan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMasterKeterangan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMasterKeterangan(params: {

    /**
     * id of Master_keterangan
     */
    id: string;
    body: AdminMasterKeterangan
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterKeterangan;
}> {

    return this.putMasterKeterangan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterKeterangan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterKeterangan;
})
    );
  }

  /**
   * Path part for operation deleteMasterKeterangan
   */
  static readonly DeleteMasterKeteranganPath = '/admin/masterKeterangans/{id}';

  /**
   * Store new Master_keterangan.
   *
   * Returns Master_keterangan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMasterKeterangan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMasterKeterangan$Response(params: {

    /**
     * id of Master_keterangan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterKeteranganService.DeleteMasterKeteranganPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Master_keterangan.
   *
   * Returns Master_keterangan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteMasterKeterangan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMasterKeterangan(params: {

    /**
     * id of Master_keterangan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteMasterKeterangan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
