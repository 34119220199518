<app-navbar></app-navbar>

<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <h1>
                    Employer Details
                </h1>
            </div>
            <div class="col-lg-4 col-md-12">
                <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-inline-block position-relative">
                        <a routerLink="/">
                            Home
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        <a routerLink="/employers">
                            Employers
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        Employer Details
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Employer Details Area -->
<div class="employer-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="single-employer-box d-sm-flex align-items-center justify-content-between">
                    <div class="d-sm-flex align-items-center">
                        <div class="image">
                            <img src="assets/images/companies/company8.png" alt="company-image">
                        </div>
                        <div class="content">
                            <h3 class="fw-semibold">
                                <a routerLink="/employer-details">
                                    Topoint
                                </a>
                            </h3>
                            <span class="location d-block">
                                London
                            </span>
                        </div>
                    </div>
                    <div class="jobs-open">
                        <span class="d-inline-block">
                            Jobs Open: 2
                        </span>
                    </div>
                </div>
                <div class="employer-details-description">
                    <h3>
                        About company
                    </h3>
                    <p>
                        Enim urna pulvinar orci leo viverra. A augue nisl convallis amet pellentesque sollicitudin. Commodo volutpat euismod nec duis iaculis mattis quam diam. Sollicitudin eu mi aliquet at tellus sed pellentesque. Id non in pharetra massa urna ullamcorper tempor. Leo tempus pharetra mi mattis ut porttitor viverra. Suspendisse massa eros pulvinar eu tortor semper sit. In tempus euismod id scelerisque convallis praesent. Viverra tortor sit volutpat elementum nibh leo rhoncus viverra vestibulum. At non nunc fames vulputate nibh pharetra eu nulla aenean. Fusce pretium purus pellentesque faucibus sed neque diam a maecenas.
                    </p>
                    <p>
                        In tempus euismod id scelerisque convallis praesent. A augue nisl convallis amet pellentesque sollicitudin. Commodo volutpat euismod nec duis iaculis mattis quam diam. Sollicitudin eu mi aliquet at tellus sed pellentesque. Id non in pharetra massa urna ullamcorper tempor.
                    </p>
                    <ul>
                        <li>
                            Leo tempus pharetra mi mattis ut porttitor viverra.
                        </li>
                        <li>
                            Suspendisse massa eros pulvinar eu tortor semper sit.
                        </li>
                        <li>
                            In tempus euismod id scelerisque convallis praesent.
                        </li>
                        <li>
                            Viverra tortor sit volutpat elementum nibh leo rhoncus viverra vestibulum.
                        </li>
                        <li>
                            At non nunc fames vulputate nibh pharetra eu nulla aenean.
                        </li>
                        <li>
                            Fusce pretium purus pellentesque faucibus sed neque diam a maecenas.
                        </li>
                    </ul>
                    <div class="images row">
                        <div class="col-6">
                            <img src="assets/images/features/feature5.jpg" alt="feature-image">
                        </div>
                        <div class="col-6">
                            <img src="assets/images/features/feature6.jpg" alt="feature-image">
                        </div>
                    </div>
                    <p>
                        A augue nisl convallis amet pellentesque sollicitudin. Commodo volutpat euismod nec duis iaculis mattis quam diam. Sollicitudin eu mi aliquet at tellus sed pellentesque. Id non in pharetra massa urna ullamcorper tempor. Leo tempus pharetra mi mattis ut porttitor viverra. Suspendisse massa eros pulvinar eu tortor semper sit. In tempus euismod id scelerisque convallis praesent. Viverra tortor sit volutpat elementum nibh leo rhoncus viverra vestibulum. At non nunc fames vulputate nibh pharetra eu nulla aenean. Fusce pretium purus pellentesque faucibus sed neque diam a maecenas.
                    </p>
                    <ul>
                        <li>
                            Leo tempus pharetra mi mattis ut porttitor viverra.
                        </li>
                        <li>
                            Suspendisse massa eros pulvinar eu tortor semper sit.
                        </li>
                        <li>
                            In tempus euismod id scelerisque convallis praesent.
                        </li>
                        <li>
                            Viverra tortor sit volutpat elementum nibh leo rhoncus viverra vestibulum.
                        </li>
                        <li>
                            At non nunc fames vulputate nibh pharetra eu nulla aenean.
                        </li>
                        <li>
                            Fusce pretium purus pellentesque faucibus sed neque diam a maecenas.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="sidebar-area">
                    <div class="box">
                        <ul class="information ps-0 mb-0 list-unstyled">
                            <li>
                                <span class="fw-medium">Founded Date:</span> 2000
                            </li>
                            <li>
                                <span class="fw-medium">Location:</span> New York
                            </li>
                            <li>
                                <span class="fw-medium">Company Size:</span> 40-50
                            </li>
                            <li>
                                <span class="fw-medium">Email:</span> <a href="mailto:hello@jove.com">hello&#64;jove.com</a>
                            </li>
                            <li>
                                <span class="fw-medium">Phone:</span> <a href="tel:+1 (514) 312-5678">+1 (514) 312-5678</a>
                            </li>
                        </ul>
                    </div>
                    <div class="box">
                        <h3>
                            Social profiles
                        </h3>
                        <ul class="socials-link mb-0 ps-0 list-unstyled">
                            <li>
                                <a href="#" target="_blank" class="facebook d-flex align-items-center">
                                    <span class="d-block">
                                        <i class="ri-facebook-fill"></i>
                                    </span>
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="twitter d-flex align-items-center">
                                    <span class="d-block">
                                        <i class="ri-twitter-x-line"></i>
                                    </span>
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="linkedin d-flex align-items-center">
                                    <span class="d-block">
                                        <i class="ri-linkedin-fill"></i>
                                    </span>
                                    Linkedin
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="box">
                        <h3>
                            Employer location
                        </h3>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2504.0527637848595!2d-0.012178123402697727!3d51.12593477172904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875f6256d342681%3A0xb7244723d598f71b!2sRoyal%20Mail!5e0!3m2!1sen!2sbd!4v1696155184932!5m2!1sen!2sbd"></iframe>
                    </div>
                    <div class="box">
                        <h3>
                            Contact us
                        </h3>
                        <form>
                            <div class="form-group">
                                <label>
                                    Your name
                                </label>
                                <input type="text" class="form-control shadow-none">
                            </div>
                            <div class="form-group">
                                <label>
                                    Your Email
                                </label>
                                <input type="email" class="form-control shadow-none">
                            </div>
                            <div class="form-group">
                                <label>
                                    Your Message
                                </label>
                                <textarea cols="30" rows="5" class="form-control shadow-none"></textarea>
                            </div>
                            <button type="submit" class="default-btn">
                                Send Message
                            </button>
                        </form>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>

<app-subscribe></app-subscribe>

<app-footer></app-footer>

<ngx-scrolltop></ngx-scrolltop>