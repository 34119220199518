<app-navbar></app-navbar>

<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <h1>
                    Employers
                </h1>
            </div>
            <div class="col-lg-4 col-md-12">
                <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-inline-block position-relative">
                        <a routerLink="/">
                            Home
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        Employers
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Employers Area -->
<div class="jobs-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="jove-grid-sorting row align-items-center">
                    <div class="col-lg-6 col-md-6 result-count">
                        <p>
                            Showing <span class="count">1 – 10</span> of 58,612 results
                        </p>
                    </div>
                    <div class="col-lg-6 col-md-6 ordering">
                        <div class="select-box">
                            <label>Sort By:</label>
                            <select class="form-select">
                                <option selected="">Default</option>
                                <option value="1">Popularity</option>
                                <option value="2">Latest</option>
                                <option value="3">Price: low to high</option>
                                <option value="4">Price: high to low</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="single-employer-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/companies/company8.png" alt="company-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/employer-details">
                                            Topoint
                                        </a>
                                    </h3>
                                    <span class="location d-block">
                                        London
                                    </span>
                                </div>
                            </div>
                            <div class="jobs-open">
                                <a routerLink="/employer-details" class="d-inline-block">
                                    Jobs Open: 2
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-employer-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/companies/company9.png" alt="company-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/employer-details">
                                            Zayper
                                        </a>
                                    </h3>
                                    <span class="location d-block">
                                        Barcelona
                                    </span>
                                </div>
                            </div>
                            <div class="jobs-open">
                                <a routerLink="/employer-details" class="d-inline-block">
                                    Jobs Open: 3
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-employer-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/companies/company10.png" alt="company-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/employer-details">
                                            Doca
                                        </a>
                                    </h3>
                                    <span class="location d-block">
                                        São Paulo
                                    </span>
                                </div>
                            </div>
                            <div class="jobs-open">
                                <a routerLink="/employer-details" class="d-inline-block">
                                    Jobs Open: 1
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-employer-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/companies/company11.png" alt="company-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/employer-details">
                                            Techstar
                                        </a>
                                    </h3>
                                    <span class="location d-block">
                                        Chicago
                                    </span>
                                </div>
                            </div>
                            <div class="jobs-open">
                                <a routerLink="/employer-details" class="d-inline-block">
                                    Jobs Open: 2
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-employer-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/companies/company12.png" alt="company-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/employer-details">
                                            Mund
                                        </a>
                                    </h3>
                                    <span class="location d-block">
                                        Seoul
                                    </span>
                                </div>
                            </div>
                            <div class="jobs-open">
                                <a routerLink="/employer-details" class="d-inline-block">
                                    Jobs Open: 3
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-employer-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/companies/company13.png" alt="company-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/employer-details">
                                            Finix
                                        </a>
                                    </h3>
                                    <span class="location d-block">
                                        Hong Kong
                                    </span>
                                </div>
                            </div>
                            <div class="jobs-open">
                                <a routerLink="/employer-details" class="d-inline-block">
                                    Jobs Open: 1
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="single-employer-box d-sm-flex align-items-center justify-content-between">
                            <div class="d-sm-flex align-items-center">
                                <div class="image">
                                    <img src="assets/images/companies/company14.png" alt="company-image">
                                </div>
                                <div class="content">
                                    <h3 class="fw-semibold">
                                        <a routerLink="/employer-details">
                                            Aoriv
                                        </a>
                                    </h3>
                                    <span class="location d-block">
                                        Toronto
                                    </span>
                                </div>
                            </div>
                            <div class="jobs-open">
                                <a routerLink="/employer-details" class="d-inline-block">
                                    Jobs Open: 2
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination-area">
                    <a routerLink="/employers" class="next page-numbers">
                        <i class="ri-arrow-left-line"></i>
                    </a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/employers" class="page-numbers">2</a>
                    <a routerLink="/employers" class="page-numbers">3</a>
                    <a routerLink="/employers" class="next page-numbers">
                        <i class="ri-arrow-right-line"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="sidebar-area">
                    <h3 class="widget-title fw-semibold">
                        Search by keywords
                    </h3>
                    <form>
                        <div class="search-box position-relative">
                            <input type="text" class="input-search" placeholder="Search....">
                            <i class="ri-search-line"></i>
                        </div>
                        <div class="form-group">
                            <label>
                                Category
                            </label>
                            <select class="form-select shadow-none">
                                <option selected>All</option>
                                <option value="1">Advertising</option>
                                <option value="2">Application</option>
                                <option value="3">Customer</option>
                                <option value="4">Design</option>
                                <option value="5">Developer</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>
                                Location
                            </label>
                            <select class="form-select shadow-none">
                                <option selected>All</option>
                                <option value="1">Tokyo, Japan</option>
                                <option value="2">New York, USA</option>
                                <option value="3">London, England</option>
                                <option value="4">Beijing, China</option>
                                <option value="5">Toronto, Canada</option>
                                <option value="6">Chicago, USA</option>
                                <option value="7">Hong Kong, China</option>
                                <option value="8">Los Angeles, USA</option>
                                <option value="9">São Paulo, Brazil</option>
                                <option value="10">Seoul, South Korea</option>
                                <option value="11">Barcelona, Spain</option>
                                <option value="12">Istanbul, Turkey</option>
                                <option value="13">Osaka, Japan</option>
                                <option value="14">Copenhagen, Denmark</option>
                                <option value="15">Madrid, Spain</option>
                                <option value="16">Bangkok, Thailand</option>
                                <option value="17">Dubai, UAE</option>
                                <option value="18">Melbourne, Australia</option>
                                <option value="19">Amsterdam, Netherlands</option>
                                <option value="20">Paris, France</option>
                            </select>
                        </div>
                        <button type="submit" class="default-btn d-block w-100">
                            Search
                        </button>
                    </form>
                </aside>
            </div>
        </div>
    </div>
</div>

<app-subscribe></app-subscribe>

<app-footer></app-footer>

<ngx-scrolltop></ngx-scrolltop>