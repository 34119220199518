/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientMasterJenis } from '../models/client-master-jenis';

@Injectable({
  providedIn: 'root',
})
export class ClientMasterJenisService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientMasterJenisList
   */
  static readonly GetclientMasterJenisListPath = '/client/masterJenis';

  /**
   * Get list of masterJenis.
   *
   * Returns list of masterJenis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientMasterJenisList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientMasterJenisList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterJenis>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterJenisService.GetclientMasterJenisListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientMasterJenis>;
        }>;
      })
    );
  }

  /**
   * Get list of masterJenis.
   *
   * Returns list of masterJenis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientMasterJenisList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientMasterJenisList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterJenis>;
}> {

    return this.getclientMasterJenisList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterJenis>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientMasterJenis>;
})
    );
  }

  /**
   * Path part for operation storeclientMasterJenis
   */
  static readonly StoreclientMasterJenisPath = '/client/masterJenis';

  /**
   * Store new Master_jenis.
   *
   * Returns Master_jenis data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeclientMasterJenis()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientMasterJenis$Response(params: {
    body: ClientMasterJenis
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterJenis;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterJenisService.StoreclientMasterJenisPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientMasterJenis;
        }>;
      })
    );
  }

  /**
   * Store new Master_jenis.
   *
   * Returns Master_jenis data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeclientMasterJenis$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientMasterJenis(params: {
    body: ClientMasterJenis
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterJenis;
}> {

    return this.storeclientMasterJenis$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterJenis;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterJenis;
})
    );
  }

  /**
   * Path part for operation getclientMasterJenisId
   */
  static readonly GetclientMasterJenisIdPath = '/client/masterJenis/{id}';

  /**
   * Get  Master_jenis by id.
   *
   * Returns Master_jenis data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientMasterJenisId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientMasterJenisId$Response(params: {

    /**
     * id of Master_jenis
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterJenis;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterJenisService.GetclientMasterJenisIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientMasterJenis;
        }>;
      })
    );
  }

  /**
   * Get  Master_jenis by id.
   *
   * Returns Master_jenis data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientMasterJenisId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientMasterJenisId(params: {

    /**
     * id of Master_jenis
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterJenis;
}> {

    return this.getclientMasterJenisId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterJenis;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterJenis;
})
    );
  }

  /**
   * Path part for operation putclientMasterJenis
   */
  static readonly PutclientMasterJenisPath = '/client/masterJenis/{id}';

  /**
   * Update Master_jenis.
   *
   * Returns Master_jenis data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putclientMasterJenis()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientMasterJenis$Response(params: {

    /**
     * id of Master_jenis
     */
    id: string;
    body: ClientMasterJenis
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterJenis;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterJenisService.PutclientMasterJenisPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientMasterJenis;
        }>;
      })
    );
  }

  /**
   * Update Master_jenis.
   *
   * Returns Master_jenis data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putclientMasterJenis$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientMasterJenis(params: {

    /**
     * id of Master_jenis
     */
    id: string;
    body: ClientMasterJenis
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterJenis;
}> {

    return this.putclientMasterJenis$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterJenis;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientMasterJenis;
})
    );
  }

  /**
   * Path part for operation deleteclientMasterJenis
   */
  static readonly DeleteclientMasterJenisPath = '/client/masterJenis/{id}';

  /**
   * Store new Master_jenis.
   *
   * Returns Master_jenis data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteclientMasterJenis()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientMasterJenis$Response(params: {

    /**
     * id of Master_jenis
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientMasterJenisService.DeleteclientMasterJenisPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Master_jenis.
   *
   * Returns Master_jenis data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteclientMasterJenis$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientMasterJenis(params: {

    /**
     * id of Master_jenis
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteclientMasterJenis$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
